.stepperButton {
  min-width: 100px;
  /* background-color: #fe6b59; */
  /* background-color: #81668c; */
  background-color: #AA346F;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 5px;
  padding: 6px 3px 6px 3px;
  box-shadow: 2px 5px 9px -3px #4E253A;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 20px 5px;
  cursor: pointer;
}

.stepperButton:hover {
  background-color: #F14C00;
}

.stepperButton1 {
  min-width: 100px;
  /* background-color: #fe6b59; */
  /* background-color: #81668c; */
  background-color: #FFE0F0;
  color: #AA346F;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 5px;
  padding: 6px 3px 6px 3px;
  box-shadow: 2px 5px 9px -3px #4E253A;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 20px 5px;
  cursor: pointer;
}

.stepperButton1:hover {
  color: #AA346F;
  background-color: #FFFFFF;
}

.bpInputStyles {
  font-weight: bold !important;
  color: #F14C00 !important;
  font-family: 'GTWalsheimProRegular';
  font-size: 16px;
  width: 200px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
}

.labelDiv {
  font-family: 'GTWalsheimProRegular';
  color: #4E253A;
  font-size: 14px;
  font-weight: bold;
  padding-left: 3px;
  padding-bottom: 3px;
  margin-top: 10px;
}