.wrapperDiv {
  padding: 10px;
  margin-right: 50px;
  margin-left: 50px;
  align-items: center;
  text-align: center;
}

.pageTitle {
  color: rgb(30, 47, 99);
  font-family: GTWalsheimProRegular;
  font-size: 25px;
  margin-left: 20px;
  margin-right: auto;
}

.calibrationCard {
  display: flex;
  background-color: #fbf4f7;
  /* background: linear-gradient(45deg, #f9e5e5, 10%, #fefcf7); */
  /* background-image: linear-gradient(to right, #f26539, #d36d80, #b76987, #943c73); */
  width: 100%;
  /* align-items: center;
    text-align: center; */
  border-radius: 20px;
  box-shadow: 0px 2px 9px 0px #888888;
  border-bottom: 1px solid rgb(255, 203, 119, 0.4);
  padding: 20px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.stepperStyles {
  background-color: transparent !important;
}

.formCard {
  /* background-color: #3e1e92; */

  /* background: linear-gradient(45deg, #ff744b, #81668c); */
  /* background: linear-gradient(45deg, #f25d52, #81668c); */
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  height: 95%;
}
.videoWrapper {
  width: 100%;
  /* display: flex; */
  background: transparent;
  border: none;
  /* background-color: #eef2fc; */
  /* background-color: #fffdd2; */
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  box-shadow: 0px 2px 5px 0px #cccaca;
  margin: 20px;
}

.card {
  background-color: #fffafa;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);

  padding: 15px;
  border-radius: 10px;
  align-items: center;
  transition: all 300ms;
  /* height: 95%; */
}

/* .card:hover {
  transform: scale(1.07);
} */

.cardContentWrapper {
  align-items: center;
  text-align: center;
  margin: 10px;
}

.cardTitleDiv {
  /* display: flex;
    text-align: center;
    align-items: center; */
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}

.cardTitleDiv iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.titleTrainginCard {
  /* margin-left: auto;
    margin-right: auto; */
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #3e1e92;
  font-family: GTWalsheimProRegular;
  font-size: 18px;
  /* color: rgb(30, 47, 99); */
}

.cardDescription {
  /* display: flex; */
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: auto;
  color: rgb(30, 47, 99);
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  align-items: center;
  text-align: center;
}

.fileWrapper {
  margin-left: 20px;
}

.fileViewButton {
  background-color: #F14C00;
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 20px;
  font-family: GTWalsheimProRegular;
  outline: none;
  margin-top: 20px;
  align-items: center;
}

.fileViewButton:hover {
  opacity: 1;
}

.filenameDiv {
  align-items: center;
  text-align: center;
  display: flex;
}

.fileIcon {
  margin-right: 5px;
  margin-top: 3px;
}

.stepTitle {
  color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 19px;
  text-align: left;
}

.bpTitle {
  position: sticky;
  bottom: 0;
  color: #db9fbe;
  font-family: GTWalsheimProRegular;
  font-size: 12px;
  text-align: right;
  font-style: italic;
  float: right;
  padding-right: 30px;
} 

.stepperButton {
  min-width: 100px;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 10px 5px 10px 5px;
  box-shadow: 2px 5px 9px -3px #4E253A;
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 20px;
  margin-top: 20px;
  cursor:pointer;
}

.stepperButton:hover {
  background-color: #4E253A;
}

.successMessage {
  color: #ffff;
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.msgStyle {
  color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 18px;
  margin:10px 20px;
  text-align: left;
}
