.color {
  color: hotpink;
}

.graphContent {
  margin-left: auto;
  margin-right: auto;
}

.graphCard {
  background: #ffffff;
  box-shadow: 0 0px 18px rgba(0, 0, 0, 0.1);
  width: 90%;
  padding: 20px 20px 40px 30px;
  /* padding: 30px; */
  margin: 16px;
  margin-top: 40px;
  border-radius: 0.25rem;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.headerDiv {
  display: flex;
}

.cardTitle {
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  color: #292f4c;
}

.cardActionDiv {
}
