.stepTitle {
  color: #6c757d;
  font-family: GTWalsheimProRegular;
  font-size: 19px;
  text-align: center;
}

.captionStyles {
  color: #6c757d;
  font-family: GTWalsheimProRegular;
  font-size: 17px;
  text-align: center;
}

.buttonDiv {
  margin: 20px 10px;
  text-align: center;
}

.buttonsWrapper {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.skipButtonStyle {
  min-width: 150px; 
  /* width: 40%; */
  background-color: #F1D4D4;
    color: #4E253A;
  font-weight: 700; 
  border-style: none;
  border: 0px solid #8D919A;
  border-radius: 15px;
  padding: 0px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 0px 40px;
  cursor:pointer;
  height:45px;
}

.skipButtonStyle:hover {
  color: #FFFFFF;
  background-color: #F14C00;
}

.nextButtonStyle {
  /* width: 40%; */
  min-width: 150px;
  background-color: #7C596A;
  color: white;
  border-style: none;
  border: 1px solid #7C596A;
  border-radius: 15px;
  padding: 0px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 0px 40px;
  cursor:pointer;
  height:45px;
}

.nextButtonStyle:hover {
    background-color: #4E253A;
}

/*  ALL Step completed  */
/* Stepper last component styles  */
.wrapperDiv {
  padding: 10px;
  margin-right: 50px;
  margin-left: 50px;
  align-items: center;
  text-align: center;
}
.card {
  background-color: #F9F2F2; /* #eef2fc; */
  border-radius: 20px;
  box-shadow: 0px 2px 7px 0px #888888;
  border-bottom: 1px solid rgb(255, 203, 119, 0.4);
  padding: 10px;
  margin-top: 20px;
  /* width: 900px; */
  margin-left: auto;
  margin-right: auto;
  /* display: flex; */
  /* 
          /*  background: linear-gradient(45deg, #f9e5e5, 60%, #fefcf7); */
  /* background-image: linear-gradient(to bottom, #f4f7fd, #1f94fa); */

  /* align-items: center;
            text-align: center; 
        */
}

.stepsCompleteMessage {
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  color: #F14C00;
}
.finalPageButtonWrapper {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
}

.navigationButtonsStyles {
  /* min-width: 150px; */
  width: auto;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border: 1px solid #7C596A;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.navigationButtonsStyles:hover {
  background-color: #4E253A;
}
.navlinkStyle {
  color: inherit;
  text-decoration: none;
}

/* .buttonsOuter {
  display: flex;
} */

.headerWrapper{
   padding-bottom: 0px;
}

.headerDiv {
  margin-left: 20px;
  margin-right: 20px;
}

.headerStyles {
    height:100%;  
    max-width:100%;
    background-color: #F9F2F2; 
    float:left;

}

.headerMenuBar {
  background-color: #F9F2F2; 
  border-radius: 0px 0px 20px 20px;
}

.headerBarWrapper {
    display: inline-flex;
    padding-left: 20px;
}
.headerBarWrapper div {
    display: flex;
}

.headerMenuItem {
    padding-right: 25px;
    font-family: GTWalsheimProRegular;
    font-size: 11px;
    color: #686666;
}