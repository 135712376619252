.deviceItemContainer{
    padding: 10px;
    background-color: transparent;
    display: flex;
}

.itemCellContainer{
    background-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
}

.itemCellHeader{
    min-width: 250px;
    max-width: 250px;
    text-align: center;
    align-items: center;
    border: none;
    padding: 10px;
    align-items: center;
    color: #322F4B;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    font-weight: bold;
}

.itemCellElement{
    text-align: center;
    align-items: center;
    background-color: #F2F2F7;
    border: none;
    padding: 10px;
    align-items: center;
    color: #322F4B;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

.actionsContainer{
    background-color: transparent;
    margin-left: auto;
    /* display: flex; */
}

.actionButtonContainer{
    text-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
     /* padding: 10px; */
    align-items: center;
    color: #322F4B;
    display: flex;
    margin-top: 36px;
}

.assignButton {
    min-width: 250px;
    max-width: 250px;
    width: 100%;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border-style: none;
    /* border-width: 10px; */
    border-radius: 5px;
    border: 3px solid #7C596A;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    cursor: pointer;
  }
  
  .assignButton:hover {
    background-color: #4E253A;
    color:white;
    border: 3px solid #4E253A;
  }
  
  .removeButton {
    min-width: 250px;
    max-width: 250px;
    width: 100%;
    background-color: #F1D4D4;
    color: #4E253A;
    padding: 10px;
    border-style: none;  
    border-radius: 5px;
    border: 3px solid #F1D4D4;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    font-weight:700;
    cursor:pointer;
  }
  
  .removeButton:hover {
    background-color: #F14C00;
    color: white;
    border: 3px solid #F14C00;
  }



  /* Modal Menu styling */
  
  .modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
  }
  
  .paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .menuTitle {
    background-color: #ffff;
    display: flex;
    width: 100%;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
  }
  
  .titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color:#4E253A;
  }