.wrapper {
    background-color: #FFF8F9;
    padding: 10px;
    flex-direction: row;
    outline: none;
    width: 270px;
    border-radius: 20px;
    align-items: center;
    overflow-x: auto;
    position: absolute;
    top: 100%;
    left: 75%;
    right: 5%;
    /* -ms-transform: translate(-20%, -20%); */
    transform: translate(-50%, -8%);
}

.profile {
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
}

.iconDivStyles svg{
    width: 16px;
    height: 16px;
    padding: 5px 7px 0px 10px;
}

.card {
    background-color: #ffff;
    width: 92%;
    border-radius: 10px;
    box-shadow: 2px 2px 15px -4px rgba(0,0,0,0.20);
    margin: 9px 0px 20px 9px;
}

.cardTitle {
    display: flex;
    margin: 10px;
    padding-top: 10px;
}
.closeIcon {
    position:absolute;
    right: 5%;
    top: 1%;
}

.title {
    display: flex;
    color: #4E253A;
}

.profileField {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: baseline;
    color: #4E253A;
    padding: 9px 10px;
    border-bottom: 1px solid #F2F2F2;
}

.avatarDiv {
    margin-left: 15px;
}
.avatar{
    margin: 9px 7px;
    width: 36;
    height: 36;
}
.patientName {
    color:#4E253A;
    font-family: GTWalsheimProRegular;
    font-size: 14px;
    font-weight:600;
    padding-bottom: 3px;
}
.gender {
    font-size: 13px;
    padding-bottom: 3px;
}

.patientDataWrapper {
    width:auto;
    border-top: 1px solid #F2F2F2;
    border-radius: 10px;
    /* padding: 10px; */
}

.divider{
    color:#F2F2F2;
    height: 0.3px;
    width:auto;
}

.boldText {
    width: 50%;
    font-family: GTWalsheimProRegular;
    font-size: 14px;
    font-weight:600;
    text-align:left;
    padding-left: 3px;
}

.regularText {
    font-family: GTWalsheimProRegular;
    font-size: 14px;
    font-weight:400;
    color:#7C596A;
}

.boldTextManyLines {
    width: 50%;
    font-family: GTWalsheimProRegular;
    font-size: 14px;
    font-weight:600;
    text-align:left;
    padding-left: 3px;
    padding-right: 22%;
}

.regularTextManyLines{
    font-family: GTWalsheimProRegular;
    font-size: 14px;
    font-weight:400;
    color:#7C596A;
    display:table-caption;
    word-spacing: 9999rem;
    text-align:left;
}