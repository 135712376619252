body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* OLD Font */
/* @font-face {
  font-family: "Brandon Text";
  src: local("Brandon Text"),
   url(./Fonts/BrandonText-Medium.woff)
      format("woff");
}

@font-face {
  font-family: "Brandon Text Light";
  src: local("Brandon Text Light"),
   url(./Fonts/BrandonText-Light.woff)
      format("woff");
} */

@font-face {
  font-family: "GTWalsheimProRegular";
  src: local("GTWalsheimProRegular"),
    /* url(./fonts/\ BrandonText-Light.woff) format("woff"); */url(./Fonts/Walsheim/GTWalsheimProRegular.woff)
      format("woff");
}

@font-face {
  font-family: "GTWalsheimProBold";
  src: local("GTWalsheimProBold"),
    /* url(./fonts/\ BrandonText-Light.woff) format("woff"); */url(./Fonts/Walsheim/GTWalsheimProBold.woff)
      format("woff");
}