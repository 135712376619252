.headerDiv {
  display: flex;
}
.cardTitle {
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  color: #292f4c;
}

.timestampLungFunction {
  margin-left: auto;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  display: flex;
}

.lfGraphCard {
  background: #ffffff;
  box-shadow: 0 0px 18px rgba(0, 0, 0, 0.1);
  width: 90%;
  padding: 20px 20px 40px 30px;
  margin-top: 40px;
  border-radius: 0.25rem;
  /* display: inline-block; */
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin-right: auto;
  margin-left: auto;
}

.lfGraphContent {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  width: 1200px;
}

.graphStyles {
  background-color: blue;
}
