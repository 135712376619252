.topDiv {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    align-items: center;
    justify-items: center;
}

.timerDiv {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    height: fit-content;
}

.startButton {
    display: flex;
    padding: 3px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.startTimer {
    display: flex;
    padding: 5px;
    align-content: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
}

.bubbleLeft {
    /* Modify size here: */
    --size: 80px;

    position: relative;
    width: fit-content;
    height: fit-content;
    background: #e4f1e3;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    align-content: center;
    justify-content: center;
}

.bubbleLeft:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: calc(var(--size) * 0.13) solid transparent;
    border-right-color: #e4f1e3;
    border-left: 0;
    margin-top: calc(var(--size) * 0.13 * -1);
    margin-left: calc(var(--size) * 0.13 * -1);
}

.addNotesBG {
    background-color: #fcf6f8 !important;
    color: black !important;
    border-bottom-left-radius: 30px !important;
    border-top-left-radius: 30px !important;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-size: 15px !important;
    font-weight: bold !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.addNotesButton {
    color: black;
}

.pauseButton {
    padding: 0px !important;
    display: flex;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.timerValue {
    display: flex;
    padding: 5px;
    align-content: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    width: 60px;
}

.characterLimitDiv {
    display: flex;
    flex-direction: row;
    justify-content: right;
    width: 96%;
    margin-top: 8px;
    font-size: 14px;
    color: rgb(185, 185, 185);
    font-family: "GTWalsheimProRegular";
}

.characterCountRed {
    color: red !important;
}

.wrapper {
    display: flex !important;
    background-color: #fcf6f8;
    padding: 10px;
    flex-direction: row;
    outline: none;
    width: 500px;
    border-radius: 10px;
    align-items: center;
    overflow-x: auto;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 79%;
    right: 5%;
    /* -ms-transform: translate(-20%, -20%); */
    transform: translate(-50%, -8%);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 0px 2px;
}

.closeIcon {
    position: absolute;
    right: 0.1%;
    top: 0.1%;
}

.headingDiv {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    width: 95%;
    padding: 5px;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #ffffff;
}

.headingAndId {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}

.heading {
    font-size: 16px;
    font-weight: bold;
}

.subjectId {
    font-size: 15px;
    font-weight: bold;
}

.tagNameAdherence {
    font-size: 14px;
    border-radius: 50px;
    border: 1.5px solid #A6A1D0;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    color: #172228;
    font-family: GTWalsheimProRegular !important;
}

.selectedTagAdherence {
    font-size: 14px;
    background-color: #A6A1D0;
    color: #172228;
    border-radius: 50px;
    border: 1.5px solid #A6A1D0;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    font-family: GTWalsheimProRegular !important;
}

.tagNameMedication {
    font-size: 14px;
    border-radius: 50px;
    border: 1.5px solid #9AB4D1;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    color: #172228;
    font-family: GTWalsheimProRegular !important;
}

.selectedTagMedication {
    font-size: 14px;
    background-color: #9AB4D1;
    color: #172228;
    border-radius: 50px;
    border: 1.5px solid #9AB4D1;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    font-family: GTWalsheimProRegular !important;
}

.tagNameVitals {
    font-size: 14px;
    border-radius: 50px;
    border: 1.5px solid #EDC490;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    color: #172228;
    font-family: GTWalsheimProRegular !important;
}

.selectedTagVitals {
    font-size: 14px;
    background-color: #EDC490;
    color: #172228;
    border-radius: 50px;
    border: 1.5px solid #EDC490;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    font-family: GTWalsheimProRegular !important;
}

.tagNameDevice {
    font-size: 14px;
    border-radius: 50px;
    border: 1.5px solid #A2DBD3;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    color: #172228;
    font-family: GTWalsheimProRegular !important;
}

.selectedTagDevice {
    font-size: 14px;
    background-color: #A2DBD3;
    color: #172228;
    border-radius: 50px;
    border: 1.5px solid #A2DBD3;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    font-family: GTWalsheimProRegular !important;
}

.tagNameOthers {
    font-size: 14px;
    border-radius: 50px;
    border: 1.5px solid #E59381;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    color: #172228;
    font-family: GTWalsheimProRegular !important;
}

.selectedTagOthers {
    font-size: 14px;
    background-color: #E59381;
    color: #172228;
    border-radius: 50px;
    border: 1.5px solid #E59381;
    padding-left: 6px;
    padding-right: 6px;
    max-height: 14px;
    padding-top: 1px;
    padding-bottom: 5px;
    margin-right: 0px;
    font-family: GTWalsheimProRegular !important;
}

.tagsDiv {
    display: flex;
    flex-direction: row;
    display: 100%;
    justify-content: space-evenly;
    padding-top: 10px;
    padding-bottom: 5px;
}

.contentArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.userName {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bolder;
    color: #4E253A;
    margin-left: 5px;
}

.notesEditor {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    width: 97%;
    padding: 6px;
    border-radius: 10px;
    margin-top: 10px;
    background-color: #ffffff;
    font-size: 15px !important;
    font-family: GTWalsheimProRegular !important;
    padding: 10px !important;
    min-height: 150px
}

.logTimeButtonDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.logTimeButton {
    margin-right: 5px;
    border-radius: 10px;
    background-color: #4E253A;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: #ffffff;
    font-size: 16px;
}

.logTimeButton:hover {
    cursor: pointer;
}

.logTimeButtonDisabled {
    margin-right: 5px;
    border-radius: 10px;
    background-color: rgb(194, 194, 194);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    color: #ffffff;
    font-size: 16px;
}

.timernoteMessage1 {
    display: flex;
    flex-direction: column; /* Change to column to center vertically */
    justify-content: center;
    align-items: center; /* Center horizontally */
    color: rgb(185, 185, 185);
    text-align: center;
    font-size: 12px;
    margin-top: 4px;
    font-family: "GTWalsheimProRegular";
    font-style: italic;
  }
