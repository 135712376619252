.wrapperCard{
        /* width: 95%; */
    
        background: transparent;
        border: none;
        /* background-color: #eef2fc; */
        /* background-color: #fffdd2; */
        padding: 10px;
        border-radius: 8px;
        align-items: center;
        color: #495057;
        box-shadow: 0px 1px 5px 0px #cccaca;
        margin: 20px;
}


.devicesListContainer{

}