.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: none;
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  /* color: blueviolet; */
  color: #793f98;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectDropdown {
  display: block;
  /* width: 200px; */
  min-width: 200px;
  /* padding: 6px 12px; */
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  line-height: 1.42857143;
  color: #8a628d;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  padding: 0px 15px;
  height:45px;

  /* min-width: 200px;
  background-color: #fff;
  font-weight: 20px;
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  border-style: none;
  border-width: 2px;
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: "0px 5px 8px -3px rgba(0,0,0,0.14)"; */
}

.dropDownItems {
  margin-top: 20px;
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  padding: 20px;
}
