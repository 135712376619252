.c3-region.region6 {
    fill: #d272a3;
    opacity: 1 !important;
}

.c3-region.region1 {
    fill: #cdb4db;
    opacity: 1 !important;
}

.c3-region.region2 {
    fill: #ffafcc;
    opacity: 1 !important;
}

.c3-region.region3 {
    fill: #f0c39d;
    opacity: 1 !important;
}

.c3-region.region4 {
    fill: #758bc5;
    opacity: 1 !important;
}

.c3-region.region5 {
    fill: #69b6c3;
    opacity: 1 !important;
}

.c3-region.regionRed {
    fill: #a2d2ff;
    opacity: 1 !important;
}

.c3-line {
    stroke-width: 2px;
}

.c3-texts .c3-text {
    font: 8px sans-serif !important;
}

.c3-texts text {
    fill: #000000 !important;
}

.c3-ygrid-line {
    stroke-dasharray: 2,5;
}