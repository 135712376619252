.wrapperDiv {
  padding: 10px;
  margin-right: 50px;
  margin-left: 50px;
  align-items: center;
  text-align: center;
}
.card {
  background-color: #eef2fc;
  border-radius: 20px;
  box-shadow: 0px 2px 9px 0px #888888;
  border-bottom: 1px solid rgb(255, 203, 119, 0.4);
  padding: 20px;
  margin-top: 20px;
  /* width: 900px; */
  margin-left: auto;
  margin-right: auto;
}

.ruleContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.vitalLabelContainer {
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.vitalsLabel {
  font-size: 20px;
  font-family: GTWalsheimProRegular;
}
.normalRangeIpContainer {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #52b788;
  border-radius: 20px;
  align-items: center;
  text-align: center;
}

.highRangeIpContainer {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #e63946;
  border-radius: 20px;
}

.weightIpContainer {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #14213d;
  border-radius: 20px;
}

.inputWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.weightWrapper {
  margin-left: auto;
  margin-right: auto;
}

.buttonsWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.buttonDiv {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.submitButton {
  min-width: 150px;
  background-color: #7C596A;
  color: white;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
}

.submitButton:hover {
  background-color: #4E253A;
}
