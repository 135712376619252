.inputCenterDiv {
    align-items: center;
    text-align: left;
  }
  
  .radioLabelForm {
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
    color: #5F5C70;
    font-weight: bold;
    padding: 5px 0px 10px 42px;
    /* margin-top: 21px; */
    /* margin-right: 15px; */
  }

  .somehting{
    border: 3px solid black;
  }

.cancelButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 150px;
    background-color: #ffffff;
    color: #8D919A;
    /* font-weight: 200; */
    border: 2px solid #8D919A;
    /* border-style: none; */
    /* border-width: 10px; */
    border-radius: 10px;
    padding:10px;
    
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
  }
  
  .cancelButton:hover {
    color: #4E253A;
    background-color: #F1D4D4;
    border: 2px solid #8D919A;
   
  }
  .submitButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 150px;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border: 0px solid #5F5C70;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
  }
  
  .submitButton:hover {
    background-color: #4E253A;
  }
  

  .progressResponseDiv {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    text-align: center;
  }
  