.c3-region.regionHighRisk {
    /*fill: #FB9595;*/
    fill: red;
}

.c3-region.regionMedRisk {
    /*fill: #FFE299;*/
    fill: yellow;
}

.c3-region.regionLowRisk {
    /*fill: #C6E8BF;*/
    fill: green;
}

.c3-texts .c3-text {
    transform: translateY(15px);
    font: 8px sans-serif !important;
}
.c3-texts text {
    fill: #FFFFFF !important;
}