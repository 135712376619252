.labelStyles {
    display:block;
    text-transform: none;
    font-size: 16px;
    font-family: GTWalsheimProRegular;
    text-align:left;
    /* color: blueviolet; */
    color: #793f98;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.select {
    display:block;
    font-family: GTWalsheimProRegular;
    font-size: 15px !important;
    text-align:left;
    min-width: 230px;
    background-color: #ffffff;
    color: #3e3f40;
    font-weight: 200;
    border-style: none;
    border-width: 10px;
    border-radius: 15px;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    caret-color: #793f98;
    
}

.inputStyle:focus {
    border-radius: 17px;
    background-color: #ffffff;
    border-color: #793f98;
  }
 
.list {
    font-family: GTWalsheimProRegular;
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #ffffff;
}
.list:li {
      font-weight: 200;
      padding-top: 12px;
      padding-bottom: 12px;
      font-family: GTWalsheimProRegular;
    font-size: 12px;
    }
.list:hover {
      background-color: "#525455";
    }
/* .Mui-selected {
      background-color: #ffffff;
    } */
/* .list:MuiMenuItem-root {
    font-family: GTWalsheimProRegular;
    font-size: 15px; 
}*/
.paper {
    border-radius: 12px;
    margin-top: 8px;
  }