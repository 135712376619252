.title{
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: bold;
    color: #909193;
}

.container{
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

.devicesWrapper {
    border-radius: 8px
}

.listContainer{
    /* height: 100px; */
    background: transparent;
    border: none;
   padding: 10px;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
    align-items: center;
    color: #495057;
    /* box-shadow: 0px 1px 5px 0px #cccaca; */
    background-color: rgb(227,233,250,0.5);
    /* background-image: linear-gradient(to right, #EBEBF0, #918EAF, #EBEBF0); */
}

.listContainerFooter{
    background-color: #7C596A;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top: 12px solid #4E253A;
    text-align: left;
}
.footerContainer{
   padding: 10px;
}

.addNewDevicesButton {
        background-color: #43414B;
        color: white;
        border: 1px solid #fff;
        border-radius: 20px;
        /* padding: 15px 15px 15px 15px; */
        outline: none;
        font-family: "GTWalsheimProRegular";
        font-size: 15px;
}