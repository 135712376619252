.labelDivStyles {
  text-align: left;
  align-items: center;
  display: inline;
}

.labelStyles {
  text-transform: none;
  font-size: 15px;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
  /* color: blueviolet; */
  color: #322F4B;
  margin-top: 10px;
  margin-bottom: 10px;
}

.requiredStyle {
    text-transform: none;
    font-size: 15px;
    font-family: GTWalsheimProRegular;
    font-weight: bold;
    /* color: blueviolet; */
    color: #322F4B;
    margin-top: 10px;
    margin-bottom: 10px;
  }

.requiredStyle::after {
    content: "*";
    color: red;
}

.inputStyle {
  min-width: 180px;
  background-color: #ffffff;
  /* color: blueviolet; */
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 0px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  /* caret-color: #7400b8; */
  caret-color: #793f98;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  border: 1px solid #4E253A;
  height:45px;
}

.inputDateStyle {
  text-transform: uppercase;
  min-width: 180px;
  background-color: #ffffff;
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 0px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  caret-color: #793f98;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  border: 1px solid #4E253A;
  height:45px;
}

.inputStyle:focus {
  border-radius: 17px;
  background-color: #ffffff;
  border-color: #793f98;
}

.inputStyle::placeholder {
  color: #8c8490;
  font-size: 1.2em;
  font-style: italic;
  font-size: 15px;
}

.inputDateStyle::placeholder {
  color: #8c8490;
  font-size: 1.2em;
  font-style: italic;
  font-size: 15px;
}

