.responsive {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 0px 2px 3px #e3e3e4;
  }
  
  .cardStyle {
    /* background-color: #36304a; */
    background-color: #E3E3E4;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    /* margin-top: 30px; */
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
    width: 100%;
  }
  
  /* Header div styles */
  .header {
    /* display: flex; */
    align-items: center;
    /* background-color: #36304a; */
    background-color: #E3E3E4;
    padding: 10px;
    color: #000;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    font-weight: lighter;
  }
  
  .mouthLabId {
    min-width: 50px;
    max-width: 50px;
    text-align: center;
    font-family: GTWalsheimProRegular;
    /* padding: 10px 0px 10px 0px; */
    padding: 5px 0px 5px 0px;
    border :7px solid transparent;
  }
  
  .temperature {
    min-width: 70px;
    max-width: 90px;
    text-align: center;
    font-family: GTWalsheimProRegular;
    /* padding: 10px 0px 10px 0px; */
  }
  
  .bp {
    min-width: 65px;
    text-align: center;
    font-family: GTWalsheimProRegular;
  }
  
  .pr {
    min-width: 65px;
    text-align: center;
    font-family: GTWalsheimProRegular;
  }
    
  .notes {
    min-width: 55px;
    text-align: center;
    font-family: GTWalsheimProRegular;
    padding-right: 15px;
  }
  
  .timeStamp {
    min-width: 79px;
    max-width: 79px;
    text-align: center;
    font-family: GTWalsheimProRegular;
  }
  
  .navContent {
    text-align: right;
    background-color: white;
    display: flex;
    float: right;
  }
  
  .buttonsIconsDivStyle {
    padding: 5px;
    align-items: center;
    display: flex;
    float: right;
    margin-right: 15px;
  }
  
  .weekbuttonsIconsDivStyle {
    padding: 5px;
    align-items: center;
    display: flex;
    float: right;
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 10px;
  }
  
  .buttonsIconsDivStyle:hover {
    /* background-color: #DEE2E6; */
    background-color: #dee2e6;
    border-radius: 10px;
  }
  
  .buttonsIconsDivStyle:hover .buttonDivStyle {
    /* background-color: #DEE2E6; */
    background-color: #dee2e6;
    border-radius: 10px;
  }
  
  .buttonsIconsDivStyle:hover .navBarbutton {
    /* background-color: #DEE2E6; */
    background-color: #dee2e6;
    border-radius: 10px;
  }
  
  .buttonDivStyle {
    align-items: center;
    display: flex;
    float: right;
    margin-right: -5px;
  }
  .navBarbutton {
    border: none;
    background: none;
    background-color: white;
    color: #343a40;
    font-size: 16px;
    align-items: center;
    font-family: GTWalsheimProRegular;
    outline: none;
  }
  
  .weekButton {
    border: none;
    background: none;
    background-color: #dee2e6;
    color: #343a40;
    font-size: 16px;
    align-items: center;
    font-family: GTWalsheimProRegular;
  }
  .sortIconStyle {
    margin-right: 3px;
    color: #343a40;
  }
  
  .filerIconStyle {
    color: #343a40;
    margin-right: 10px;
  }
  
  /* Style for date on the nav content */
  
  .notificationsLableContainer {
    color: #ffff;
    margin-left: 5px;
    font-family: GTWalsheimProRegular;
    font-size: 11px;
    display: flex;
    align-items: center;
    display: flex;
    margin-right: auto;
  }
  
  .notificationLable {
    /* background-color: #40916c;
        
        border-radius: 10px;
        font-family: GTWalsheimProRegular;
        font-size: 11px;
        padding: 0px 10px 0px 10px; */
    padding: 5px;
    align-items: center;
    display: flex;
  
    background-color: #dee2e6;
    border-radius: 10px;
    font-size: 17px;
  }
  
  .topIcondiv {
    margin-left: auto;
  
    /* color: #6c757d; */
  }
  .menuIconTop {
    margin-top: 8px;
    margin-bottom: 3px;
    color: #535354;
  }
  
  .containerNav {
    display: flex;
    align-items: center;
  }
  