.backColor {
  background-color: #F3E8EE;
  /* height: 100%; */
  /* background-image: url(`../../../../../../../Assets/Images/QV-BG.png); */
}

.cardHighRisk {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 10px 30px;
  padding: 25px 15px 25px 15px;
  height: 30px;
  border-radius: 10px;
  transition: 0.3s linear all;
  /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
  background-color: #FFF8F9;
  opacity: 0.7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
  border: 0px;
}

.cardHighRisk:hover {
  box-shadow: 2px 2px 3px #495057;
}

.iconDivStyles {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  margin: 0px;
  padding-top: 0px;
  background-color: #F1D4D4;
  border-radius: 50%;
  justify-content: center;
}

.countDivStyles {
  width: 70%;
  text-align: right;
  padding: 0px 0px 10px;
}

.patientCountHighRisk {
  font-weight: 1000;
  font-size: 30px;
  color: #4E253A;
  text-align: right;
  font-family: "GTWalsheimProRegular";
}

.patientCatagoryHighRisk {
  font-size: 16px;
  color: #4E253A;
  text-align: right;
  font-family: "GTWalsheimProRegular";
  font-weight: bold;
}

.highRiskIcon {
  width: 40px;
  height: 40px;
  fill: #4E253A;
}

.ll {
  display: flex;
  align-items: center;
}

.something {
  display: flex;
  align-items: center;
  width: 50%;
}

.ok {
  text-align: right;
  width: 50%;
}

.iconDivStyles :hover>.highRiskIcon {
  color: #03045e;
}

.graphsAidarView {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  height: 50px;
  border-radius: 5px;
  transition: 0.3s linear all;
  /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
  background-color: #535354;
  opacity: 0.7;
  /* display: flex; */
  text-align: center;
  align-items: center;
  /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
}

.graphValues {
  margin-top: 11px;
  font-size: 18px;
  color: #ffff;
  text-align: center;
  align-items: center;
  font-family: GTWalsheimProRegular;
}

/* Modal Menu styling */

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
        margin-right: 90%;
        top: 50; */
}

.paper {
  background-color: white;
  outline: none;
  border-radius: 10px;
  padding: 10px 0px 10px 0px;
  align-items: center;
  width: 800px;
  height: 480px;
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 31%;
  right: 69%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menuTitle {
  background-color: #ffff;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: GTWalsheimProRegular;
}

.iconDivModal {}

.titleText {
  font-style: GTWalsheimProRegular;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.buttonStyles {}

.patientListDiv {
  display: flex;
}

/*red color hover */

.cardHighRiskRed {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  height: 50px;
  border-radius: 5px;
  transition: 0.3s linear all;
  /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
  background-color: #e63946;
  opacity: 0.7;
  display: flex;
  /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
}

.cardHighRiskRed:hover {
  background-color: #f4f7fd;
  border: 1px solid #e63946;
}

.cardHighRiskRed:hover .highRiskIconred {
  color: #e63946;
}

.cardHighRiskRed:hover .patientCatagoryHighRiskred {
  color: #e63946;
}

.cardHighRiskRed:hover .patientCountHighRiskred {
  color: #e63946;
}

.patientCountHighRiskred {
  font-size: 26px;
  color: #f4f7fd;
  text-align: right;
  font-family: GTWalsheimProRegular;
}

.patientCatagoryHighRiskred {
  font-size: 15px;
  color: #f4f7fd;
  text-align: right;
  font-family: GTWalsheimProRegular;
}

.highRiskIconred {
  color: #f4f7fd;
}

.graphButtons {
  /* background: transparent; */
  background-color: #06293d;
  border: none;
  /* background-color: #faf9f9; */
  padding: 10px;
  border-radius: 10px;
  align-items: center;
  color: #495057;
  box-shadow: 0px 2px 9px 0px #888888;
  /* border-left: 5px solid rgb(229, 152, 155); */
  margin-right: 10px;
  margin-left: 10px;
}

.graphIconDiv {
  align-items: center;
  text-align: center;
}

.iconStyleGraphButton {
  margin-left: auto;
  margin-right: auto;
  color: #e85c4b;
}

.titleDivStyle {
  text-align: center;
}

.buttonTitle {
  margin-right: auto;
  margin-left: auto;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  color: #ffff;
}

/* timestamp at bottom styles */
.timeStampStyles {
  font-size: 17px;
  font-family: GTWalsheimProRegular;
  align-items: center;
  text-align: center;
  color: #6f7071;
  /* margin-right: 20px; */
  margin-left: 25px;
  margin-bottom: 20px;
  bottom: 12%;
  position: fixed;
  width: 550px;
}

.timeStampText {}

.timeStampDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: GTWalsheimProRegular;
  font-weight: normal;
}