.NAMLLogsHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFF1F4;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    height: 4vh;
}

.NApageTitle {
    color: #4E253A;
    font-family: "GTWalsheimProRegular";
    font-size: 30px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: auto;
}

.mlLogWrapper {
    padding: 0px 10px 0px 10px;
}

.TableWrapper {
    background: transparent;
    border: none;
    padding: 10px;
    border-radius: 10px;
    align-items: center;
    color: #495057;
    box-shadow: 0px 2px 5px 0px #cccaca;
    margin-left: 10%;
    margin-bottom: 20px;
    width: 78.9%;
}

.TableWrapperGrid {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.TableWrapperGrid::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
}

.responsive {
    overflow-x: auto;
    width: 100%;
    /* padding: 20px; */
}

.rowCellSubjectID {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #f5f6f8;
    font-size: 18px;
    font-family: GTWalsheimProRegular;
}

.nameDiv {
    margin-left: 3px;
    align-items: center;
}

/* Device Status Vailable */
.deviceStatus {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #D9EEEB;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

/* Device Status Not available */
.deviceStatusNotAvailable {
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    background-color: #F9DED7;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

.rowCellMouthLabID {
    max-width: 150px;
    font-size: 18px;
    min-width: 150px;
    border: 4px solid #F5F6F8;
    background-color: #fff;
    text-align: center;
    font-family: "GTWalsheimProRegular";
}

.actionHeader {
    max-width: 100px;
    min-width: 100px;
    text-align: center;
    align-items: center;
    font-size: 18px;
    font-family: "GTWalsheimProRegular";
}

.buttonDiv {
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
}

.assignButton {
    /* min-width: 150px; */
    width: 100%;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border-style: none;
    border-width: 10px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    cursor: pointer;
}

.assignButton:hover {
    background-color: #4E253A;
    color: white;
    border: 1px solid #4E253A;
}

.removeButton {
    /* min-width: 150px; */
    width: 100%;
    background-color: #F1D4D4;
    color: #4E253A;
    font-weight: 700;
    border-style: none;
    border-width: 10px;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    cursor: pointer;
}

.removeButton:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
}

.buttonDiv {
    text-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 20px; */
}

.paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 340px;
    width: 350px;
}

.menuTitle {
    background-color: #FFF1F8;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    color: #4E253A;
}

.titleText {
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color: #4E253A;
}

.paperUnassignML {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 340px;
    width: 350px;
}

.cardHighRiskUA {
    box-shadow: 0px 0px 19.418px 0px rgba(0, 0, 0, 0.15);
    margin: 20px 60px 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #d0cdce;
    opacity: 0.7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 0px;
    min-width: 180px;
}

.cardHighRiskUA:hover {
    box-shadow: 2px 2px 3px #495057;
    background-color: #FFE0E7;
    cursor: pointer;
}

.cardHighRiskUA:hover .highRiskIconUA {
    fill: #e6cad0 !important;
}

.cardHighRiskA {
    box-shadow: 0 0 4px 2px #4E253A;
    margin: 20px 60px 20px;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #7C596A;
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 0px;
    min-width: 180px;
}

.cardHighRiskA:hover {
    box-shadow: 2px 2px 3px #7C596A;
    background-color: #A03268;
    cursor: pointer;
}

.cardHighRiskA:hover .highRiskIconA {
    fill: #A03268 !important;
}

.iconDivStyles {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    background-color: #ffff;
    border-radius: 50%;
    justify-content: center;
}

.iconDivStyles :hover>.highRiskIconA {
    color: #03045e;
}

.iconDivStyles :hover>.highRiskIconUA {
    color: #03045e;
}

.highRiskIconA {
    width: 30px !important;
    height: 30px !important;
    fill: #7C596A !important;
}

.highRiskIconA:hover {
    fill: #A03268 !important;
}

.highRiskIconUA {
    width: 30px !important;
    height: 30px !important;
    fill: #968E92;
}

.highRiskIconUA:hover {}

.countDivStyles {
    width: 70%;
    text-align: center;
}

.patientCountHighRiskUA {
    font-weight: bolder;
    font-size: 24px;
    color: #4E253A;
    text-align: center;
    font-family: "GTWalsheimProRegular";
}

.patientCountHighRiskA {
    font-weight: bolder;
    font-size: 24px;
    color: #f5f6f8;
    text-align: center;
    font-family: "GTWalsheimProRegular";
}

.MLInstructions {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    justify-items: center;
    padding: 10px;
    margin-top: 20px;
}

.deviceSelectionWarning {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: -10px;
    text-align: center;
}

.deviceSelectionWarning1 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: -10px;
    margin-bottom: -25px;
    text-align: center;
}

.mlImg {
    width: 100px;
    height: 130px;
}

.mouthLabImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mouthLabImgDiv1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.submitButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 40%;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: #AA346F;
    border: 1px solid #AA346F;
    color: white;
    font-weight: 600;
}

.submitButton:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
}

.cancelButton {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 40%;
    color: #AA346F;
    background-color: #FFFFFF;
    border: 1px solid #AA346F;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
}

.cancelButton:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 10px;
}

.buttonContainer1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
}

.selectedML {
    font-weight: bolder;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 3px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    align-items: center;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    color: #4E253A;
}

.uaSuccessMsg {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    padding-left: 10px;
    font-family: "GTWalsheimProRegular";
    color: #F14C00;
}

.skipMLSelectionDiv {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    color: #fff;
    background-color: #7C596A;
    border-radius: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.skipMLSelectionDiv:hover {
    background-color: #AA346F;
    cursor: pointer;
}

.footerDiv {
    position: fixed;
    bottom: 0%;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 20px;
    padding-top: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.enterManuallyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    justify-items: left;
    align-content: center;
    background-color: #65394F;
    color: #F7E7E7;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    padding-left: 4px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
    column-gap: 10px;
    width: 220px;
    text-align: center;
}

.enterManuallyButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.manualLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    padding-bottom: 2px;
}

.touchIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50px;
    background-color: #F7E7E7;
}