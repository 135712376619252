.statusItemContainer{
    padding: 10px;
    background-color: transparent;
    display: flex;
}

.itemCellContainer{
    background-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
}

.itemCellHeader{
    min-width: 200px;
    max-width: 200px;
    text-align: center;
    align-items: center;
      border: none;
     padding: 10px;
      align-items: center;
      color: #322F4B;
      font-family: "GTWalsheimProRegular";
      font-size: 16px;
      font-weight: bold;
}

.itemCellElement{
    text-align: center;
    align-items: center;
    background-color: #F2F2F7;
      border: none;
     padding: 10px;
      align-items: center;
      color: #322F4B;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}


.registerButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #D5D2EB;
    color: #5B5687;
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #D5D2EB;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }


  
.curRegisterButton {
  min-width: 150px;
  max-width: 150px;
  width: 100%;
  background-color: #D5D2EB;
  color: #5B5687;
  font-weight: bold;
  border-style: none;
  /* border-width: 10px; */
  border-radius: 12px;
  border: 3px solid #5B5687;
  padding: 10px;
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
}


  .terminateButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: rgb(150,153,154, 0.2);
    /* color: rgba(128, 128, 128, 0.75);; */
    color: #535354;
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #DFE2E9;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }

  .curTerminateButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: rgb(150,153,154, 0.2);
    /* color: rgba(128, 128, 128, 0.75);; */
    color: #535354;
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #535354;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }

  
  .activeButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #D9EEEB;
    color: #3AD26E;
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
/* border: 3px solid #3AD26E; */
    border: 3px solid #D9EEEB;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }

  .curActiveButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #D9EEEB;
    color: #3AD26E;
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
/* border: 3px solid #3AD26E; */
    border: 3px solid #3AD26E;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }
    
  .calibrateButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #F5D9E2;
    color: rgba(174, 88, 104, 0.75);
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #F5D9E2;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }


  .curCalibrateButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #F5D9E2;
    color: rgba(174, 88, 104, 0.75);
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #C17887;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }


  .completedButton {
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #F4E3CD;
    color: rgba(210, 149, 46, 0.75);
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #F4E3CD;
   padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    /* box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1); */
  }

  .curComplete {
   
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    background-color: #F4E3CD;
    color: rgba(210, 149, 46, 0.75);
    font-weight: bold;
    border-style: none;
    /* border-width: 10px; */
    border-radius: 12px;
    border: 3px solid #DAA955;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
  }

  /* Modal Menu styling */
  
  .modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
  }
  
  .paper {
    background-color: #fff;
    outline: none;
    border-radius: 10px;
    padding: 0.5%;
    padding-bottom: 1%;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  
  .menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
  }
  .iconDivModal {
  }
  
  .titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    /* margin-left: auto; */
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding-left: 42px;
    text-align: left;
    color: #43253A;
  }


