.avatar {
    border: 1px solid #4E253A;
}

.avatarTitleDiv {
    display: flex;
    flex-direction: column;
    row-gap: 0px;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
}

.avatarTitle {
    display: flex;
    font-family: 'GTWalsheimProRegular';
    font-size: 18px;
    color: #4E253A;
    font-weight: 300;
}

.avatarTitle1 {
    display: flex;
    font-family: 'GTWalsheimProRegular';
    font-size: 12px;
    color: #4E253A;
}

.qvHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-top: 10px;
    justify-content: center;
    background-color: #E4DFE9;
    padding-bottom: 10px;
}

.drawerTitle {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    font-family: "GTWalsheimProRegular";
    font-size: 20px;
    font-weight: bolder !important;
    align-items: center;
    justify-items: center;
    align-content: center;
    justify-content: center;
    padding: 10px;
    background-color: #FFDBEC;
    color: #4E253A;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
    z-index: 999;
}

.mlWarningDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 5px;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.warningIcon {
    display: flex;
    background-color: #F14C00;
    padding: 3px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    margin-top: 5px;
    margin-left: 5px;
}

.warningIconIn .svg {
    height: 20px;
    width: 20px;
}

.warningText {
    color: #4E253A;
    font-weight: bold;
}

.mlInsDiv {
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    background-color: inherit;
    flex-wrap: wrap;
}

.setpOneDiv {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    align-content: center;
    justify-items: space-between;
    background-color: inherit;
}

.setpTwoDiv {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    align-content: center;
    justify-items: space-between;
    background-color: inherit;
}

.setpThreeDiv {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    align-content: center;
    justify-items: space-between;
    background-color: inherit;
}

.step1Ins {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
}

.step2Ins {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
}

.step3Ins {
    display: flex;
    flex-wrap: wrap;
    font-size: 20px;
}

.nextButton {
    position: absolute;
    bottom: 0;
    height: 60px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
    background-color: #4E253A;
}

.nextButton:hover {
    cursor: pointer;
    box-shadow: #4E253A 0px 5px 15px 0px;
}

.MuiLinearProgress-colorPrimary {
    background-color: #4E253A;
}

.MuiLinearProgress-barColorPrimary {
    background-color: #f1c7c7;
}

.cancelButton {
    position: absolute;
    bottom: 0;
    height: 60px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    font-weight: bold;
    color: #4E253A;
    background-color: #f1c7c7;
}

.cancelButton:hover {
    cursor: pointer;
    box-shadow: #4E253A 0px 5px 15px 0px;
}

.progressDiv {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
}

.stepTwoWarningDiv {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
    text-align: center;
}

.qvDiv {
    background-color: #f4e7ee;
    height: 100%;
}

.cardHighRisk {
    box-shadow: 0px 0px 2px #495057;
    margin: 10px 20px;
    padding: 5px 5px 5px 5px;
    border-radius: 10px;
    transition: 0.3s linear all;
    /* background-image: linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    background-color: #FDF8FA;
    opacity: 0.7;
    display: flex;
    /* border: 1px solid linear-gradient(to right, #00B4D8 ,#ADE8F4); */
    border: 1px solid #A69B9B;
}

.cardHighRisk:hover {
    box-shadow: 2px 2px 3px #495057;
}

.iconDivStyles {
    height: 55px;
    width: 60px;
    display: flex;
    align-items: center;
    margin: 0px;
    padding-top: 0px;
    background-color: #F2EFEF;
    border-radius: 5px;
    justify-content: center;
    border: 1px solid #FFF;
}

.countDivStyles {
    width: 70%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.patientCountHighRisk {
    font-weight: 1000;
    font-size: 28px;
    color: #4E253A;
    text-align: right;
    font-family: "GTWalsheimProRegular";
}

.patientCountHighRiskInput {
    font-weight: 1000;
    font-size: 20px;
    color: #4E253A;
    text-align: center;
    font-family: "GTWalsheimProRegular";
    background-color: #e7e7e7;
    border: none;
    width: 70px;
    height: 30px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border: 1px solid #FFF;
}

.patientCatagoryHighRisk {
    font-size: 11.2px;
    color: #4E253A;
    text-align: center;
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    background-color: #DEDADA;
    width: 74px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #FFF;
    padding-top: 1px;
}

.highRiskIcon {
    width: 35px;
    height: 35px;
    fill: #4E253A;
}

.ll {
    display: flex;
    align-items: center;
}

.something {
    display: flex;
    align-items: center;
    width: 50%;
}

.ok {
    text-align: right;
    width: 50%;
}

.iconDivStyles :hover>.highRiskIcon {
    color: #03045e;
}

/* timestamp at bottom styles */
.timeStampStyles {
    font-size: 12px;
    font-family: 'GTWalsheimProRegular';
    align-items: center;
    text-align: center;
    color: #6f7071;
    /* margin-right: 20px; */
    margin-left: 25px;
    margin-bottom: 20px;
    bottom: 8%;
    position: relative;
    width: 545px;
    margin-top: 15px;
}

.painLevelRating {
    display: flex;
    flex-direction: row;
    column-gap: 14px;
    padding: 5px;
}

.ratingdiv {
    display: flex;
    height: 30px;
    width: 30px;
    border: solid 2px #4E253A;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 6px;
    background-color: #FFDBEC;
}

.selectedRatingdiv {
    display: flex;
    height: 30px;
    width: 30px;
    border: solid 2px #4E253A;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    border-radius: 6px;
    background-color: #4E253A;
    color: #FFFFFF;
    font-weight: 500;
}

.painDiv {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding: 10px;
    border: 1.659px solid #F1BBD5;
    margin-left: 20px;
    border-radius: 6px;
    margin-top: 10px;
    background-color: #FFEEF6;
}

.painDivDesc {
    font-family: 'GTWalsheimProRegular';
    font-size: 15px;
    font-weight: bold;
    color: #4E253A;
    margin-bottom: 5px;
}

.addNewNoteDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 10px;
    height: fit-content;
    align-items: center;
    column-gap: 0px;
    width: 100%;
    padding-right: 10px;
}

.notesEditor {
    position: relative;
    width: 95%;
    padding-right: 10px;
    border-radius: 2px;
    font-size: 12px;
    align-items: start;
    justify-content: left;
    margin-top: 10px;
    margin-left: 20px;
    padding-left: 10px !important;
    display: inline-block;
    overflow: hidden;
    font-family: GTWalsheimProRegular !important;
    background-color: #FFFFFF;
    padding-top: 0px !important;
    min-height: 60px;
    border: 1.659px solid #F1BBD5;
}

.buttonsDiv {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 0px;
    width: 100%;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
}

.retakeButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    font-weight: bold;
    color: #4E253A;
    background-color: #FFDBEC;
    height: 60px;
}

.retakeButton:hover {
    cursor: pointer;
    box-shadow: #4E253A 0px 5px 15px 0px;
}

.uncompleteButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #7C596A;
    height: 60px
}

.uncompleteButton:hover {
    cursor: pointer;
    background-color: #4E253A;
    box-shadow: #4E253A 0px 5px 15px 0px;
}

.completeButton {
    display: flex;
    position: relative;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #7C596A;
    height: 60px;
}

.completeButton:hover {
    cursor: pointer;
    background-color: #4E253A;
    box-shadow: #4E253A 0px 5px 15px 0px;
}

.loaderDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
}

.loaderMessageSuccess {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    color: rgb(1, 138, 12);
}

.loaderMessageFailure {
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: GTWalsheimProRegular;
    font-size: 20px;
    color: red;
}
.errorInput {
    border: 2px solid red !important;
}
.errorText{
    color :red;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;
}
.manualTimeContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
}
.manualTimeContainer ul{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding-left: 5px;
    align-items: baseline;
}
.timeStampStyles{
    display: flex;
    flex-direction: column;
}
.timeStampStyles label {
align-self: baseline;
margin-bottom: 10px;
font-family: "GTWalsheimProRegular";
}