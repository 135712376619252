.labelDivStyles {
  text-align: left;
  align-items: center;
  /* padding: 10px; */
  /* margin-bottom: 20px; */
}

.labelStyles {
  /* text-transform: capitalize; */
  font-size: 15px;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
  /* color: blueviolet; */
  color: #322F4B;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputStyle {
  min-width: 200px;
  background-color: #ffffff;
  /* color: blueviolet; */
  /* color: #793f98; */
  color: #405c72;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  caret-color: #793f98;
}

.checkboxLabel {
  color: #4E253A;
  font-family: GTWalsheimProRegular;
  padding: 10px 20px 10px 20px;
  border-radius: 20px;
  text-transform: capitalize;
  border: 1px solid #4E253A;
  cursor: pointer;
  margin-bottom: 20px;
}

.checkInput {
  position: relative;
  left: 0;
  top: 0;
  width: 1px; 
  height: 1px;
  opacity: 0;
  z-index: -1;
  color: #4E253A;
  border: 2px solid #4E253A;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 30px;
}

input[type="checkbox"]:checked + label {
  border: 1px solid #4E253A;
  color: #ffffff;
  background-color: #4E253A;
  transition: all 0.2s;
}

input[type="checkbox"]:checked + label::before {
  content: " \2713";
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
  font-size: 12px;
  padding: 0px 12px 10px 0px;

}

.checkboxLabel::before {
  display: inline-block;
  /* font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased; */
  font-family: GTWalsheimProRegular;
  font-size: 21px;
  content: "\FF0B";
  transition: transform 0.3s ease-in-out;
  white-space: nowrap;
}

/* input[type="checkbox"] {
    width: 100px;
    height: 100px;
     width: 20px;
    height: 20px;
    border: 1px solid #7400b8;
    position: relative;
    vertical-align: -2px;
   
    color: #7400b8;
    ✔
  }
  
  label {
    padding: 10px;
    cursor: pointer;
  }
  
  */
