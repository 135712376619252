.responsive {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .rows {
    overflow-x: auto;
    width: 100%;  
    /* margin-top: 2px;
    margin-bottom: 2px; */
  }
  .tableBody {
    /* border: 5px solid gray; */
  }
  
  .content {
    align-items: center;
    background-color: #F5F6F8;
    /* padding: 10px;
    margin-top: 20px; */
  }
  
  .highRisk {
    color: #ef233c;
    background-color: #FFDAE1;
    font-family: "GTWalsheimProRegular";
  }
  
  .timeStamp {
    min-width: 45px;
    max-width: 45px;
    font-size: 18px;
    text-align: center;
    border: 7px solid #F1EFEF;
    background-color: #F9F9F9;
    line-height: 1.4;
    font-family: "GTWalsheimProRegular";
    
  }
  
  .deviceId {
    min-width: 40px;
    font-size: 18px;
    max-width: 40px;
    border: 7px solid #F1EFEF;
    background-color: #F9F9F9;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }
  
  .AIDI {
    min-width: 25px;
    font-size: 18px;
    max-width: 25px;
    border: 7px solid #F1EFEF;
    background-color: #ECE7E0;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }
  
  .AIDIlowValue{
    min-width: 25px;
    font-size: 18px;
    max-width: 25px;
    border: 7px solid #F1EFEF;
    background-color: #C6E8BF;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }

  .AIDImediumValue{
    min-width: 25px;
    font-size: 18px;
    max-width: 25px;
    border: 7px solid #F1EFEF;
    background-color: #FFE299;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }

  .AIDIhighValue{
    min-width: 25px;
    font-size: 18px;
    max-width: 25px;
    border: 7px solid #F1EFEF;
    background-color: #FB9595;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }

  .averageHR {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    border: 7px solid #F1EFEF;
    background-color: #F9F9F9;
    line-height: 1.4;
   
  }
  
  .averageRR {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    border: 7px solid #F1EFEF;
    background-color: #F9F9F9;
    line-height: 1.4;
   
  }
  .tooltip {
    font-size: 16px;
    font-family: GTWalsheimProRegular;
  }
  
  
  /*Dynamic styling classes*/
  
  .riskOne {
  background-color: #FFDAE1;
  color: #000;
  }
  
  .riskZero{
    background-color: #F5F6F8;
    color: #000;
  }