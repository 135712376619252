.responsive {
  margin-right: auto;
  margin-left: auto;
}

.noDataWrapper {
    position:absolute;
    top: 29%;
    left: 30%;
}

.noData {
  position: absolute;
  clear: both;
  display: inline-block;
  white-space: nowrap;
  left: 33%;
  bottom: 47%;
  color:#4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 29px;
  font-weight: 700;
  padding-bottom: 3px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}
