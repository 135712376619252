.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: none;
  font-size: 16px;
  font-family: "GTWalsheimProRegular";
  /* color: blueviolet; */
  color: #0000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectDropdown {
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0px 10px;
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  line-height: 1.42857143;
  color: #000;
  background-color: #fff;
  background-image: none;
  border: 1px solid #a7a7a7;
  border-radius: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  height:45px;
}

.dropDownItems {
  font-size: 18px;
  font-family: "GTWalsheimProRegular";
  padding: 20px;
}

.dropDownItems:hover {
  background-color: #A7C5E2;
}