
.labelDivStyle {
  font-family: "GTWalsheimProRegular";
  font-size: 15px;
  font-weight: bold;
  /* margin-left: 10px; */
  min-width: 110px;
  align-items: center;
  text-align: left;
  margin-top: 20px;
  display: flex;
  color: #322F4B;
}

.subtextStyles {
  font-family: GTWalsheimProRegular;
  margin-left: 10px;
  font-style: oblique;
  /* min-width: 110px; */
  align-items: center;
  text-align: left;
  /* margin-top: 10px; */
  /* display: flex; */
  /* color: #793f98; */
  color: #4E253A;
}

.radioLabel {
  color: #4E253A;
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  /* border-radius: 20px; */
  text-transform: capitalize;
  /* border: 1px solid #7400b8; */
  margin-left: 5px;
  font-size: 16px !important;
}

.wrapper {
  display: flex;
  align-items: center;
}

.radioWrapper {
  display: flex;
  align-items: center;
  /* padding: 5px 0px 10px 10px; */
  /* background-color: black; */
  margin: 4px;
}

.radioInput {
  margin-left: 30px;
  width: 18px;
  height: 18px;
}

.radioInput:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #4E253A;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.radioInput:checked {
  background-color: blue;
  color: wheat;
}

.radioInput:checked {
  background-color: blue;
  color: wheat;
}

.radioLabel:checked:after + label {
  position: relative;
  background-color: #fe6b59;
  border: 2px solid white;
}
