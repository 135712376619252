.containerForMessage {
    height: 85%;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
  }
  
  .msgNoIPL {
    font-family: "GTWalsheimProRegular";
    font-size: 24px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.00735em;
  }