.circlesList {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    justify-content: left;
}

.tootlTipHeading {
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 10px;
    font-style: normal;
}

.greenCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    background-color: #C6E8BF;
    border: 0.5px solid #cccccc;
}

.orangeCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    border: 0.5px solid #cccccc;
    background-color: #FFE299;
}

.redCircle {
    border-radius: 50px;
    height: 20px;
    width: 20px;
    background-color: #FB9595;
    border: 0.5px solid #cccccc;
}

.circlesListItem {
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    font-style: normal;
    align-items: center;
    justify-content: left;
}

.aidiGraphWrapper {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 50px;
    margin-bottom: 10px;
    background-color: #fbf4f7;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.containerHeader {
    display: flex;
    flex-direction: row;
    background-color: #985C70;
    margin: -10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    justify-content: space-between;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-bottom: 10px;
    padding: 0px;
}

.mainMessage {
    display: flex;
    font-size: large;
    font-weight: bold;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    padding: 10px;
}

.noDataWrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fbf4f7;
}

.subMessage {
    display: flex;
    font-weight: normal;
    font-style: italic;
    font-family: "GTWalsheimProRegular";
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 10px;
}

.aidiGraphHeadingDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
}

.expandAidiButton {
    color: #FFFFFF !important;
    font-size: 14px !important;
    font-family: "GTWalsheimProRegular";
}

.aidiIconWrapper {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    border-radius: 50px;
    padding: 1px;
    margin-right: 10px;
}

.legendDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.baselineLegendDiv {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineLegendDivRec {
    font-size: 15px;
    color: #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.baselineIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    font-weight: normal;
    /*background-color: #d7e4d7 ;
    background-color: #e1e8de;*/
    margin-right: 10px;
    margin-left: 10px;
    color: #000000;
    border-radius: 10px;
}

.baselineIconRec {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    font-weight: bold;
    /*background-color: #d7e4d7 ;
    background-color: #e1e8de;*/
    margin-right: 10px;
    margin-left: 10px;
    color: #FFFFFF;
}

.legendDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    padding: 8px;
    padding-left: 10px;
    background-color: #faeaea;
    margin: -10px;
    margin-bottom: 0px;
}

.graphDiv {
    display: flex;
    flex-direction: row;
    margin-left: 0px;
}

.brLegendDiv {
    font-size: 14px;
    color: #F14C00;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "GTWalsheimProRegular";
}

.hrLegendDiv {
    font-size: 14px;
    color: #694FC0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "GTWalsheimProRegular";
}

.hrLegendDiv1 {
    font-size: 14px;
    color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: "GTWalsheimProRegular";
}