.responsive {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rows {
    overflow-x: auto;
    width: 100%;  
    /* margin-top: 2px;
    margin-bottom: 2px; */
   
  }
  .tableBody {
    /* border: 5px solid gray; */
  }
  
  .content {
    align-items: center;
    background-color: #F5F6F8;
    /* padding: 10px;
    margin-top: 20px; */
  }
  
  .highRisk {
    color: #ef233c;
    background-color: #FFDAE1;
    font-family: "GTWalsheimProRegular";
  }
  
  .timeStamp {
    min-width: 70px;
    max-width: 70px;
    font-size: 18px;
    text-align: center;
    background-color: #F5F6F8;
    line-height: 1.4;
    font-family: "GTWalsheimProRegular";
    
  }
  
  .mouthLabId {
    min-width: 45px;
    font-size: 18px;
    max-width: 50px;
    border: 7px solid #F5F6F8;
    background-color: #fff;
    text-align: center;
    font-family:"GTWalsheimProRegular";
    padding: 10px 0px 10px 0px;
  }
  
  .manualMouthLabId {
      min-width: 45px;
      font-size: 18px;
      max-width: 45px;
      border: 7px solid #F5F6F8;
      background-color: #E4EDF4;
      text-align: center;
      font-family:"GTWalsheimProRegular";
      padding: 10px 0px 10px 0px;
    }
  
  .temperature {
    font-size: 18px;
    min-width: 60px;
    /* background-color: #F5F6F8; */
    text-align: center;
    line-height: 2.4;
  }
  
  .bp {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
   
  }
  
  .pr {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
   
  }
  
  .spo2 {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
    /* box-shadow: 0 6px 2px -6px grey; */
  }
  
  .hr {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
    
  }
  .hrv {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
   
  }
  
  .rr {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
   
  }
  
  .fev1 {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
    
  }
  
  .fvc {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
    
  }
  
  
  .fev1fvc {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
    
  }
  
  .pef {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
  }
  
  .notes {
    min-width: 60px;
    font-size: 18px;
    text-align: center;
    /* background-color: #F5F6F8; */
    line-height: 1.4;
  }
  
  
  .tooltip {
    font-size: 16px;
    font-family: GTWalsheimProRegular;
  }
  
  
  /*Dynamic styling classes*/
  
  .riskOne {
  background-color: #FFDAE1;
  color: #000;
  }
  
  .riskZero{
    background-color: #F5F6F8;
    color: #000;
  }
  