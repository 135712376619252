.avatar {
    border: 1px solid #4E253A;
}

.avatarTitleDiv {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.qvFooter {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 20px;
    justify-content: space-between;
    background-color: #E4DFE9;
    padding-bottom: 10px;
    bottom: 0;
    position: fixed;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    box-sizing: border-box;

}

.qvHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-top: 0px;
    justify-content: center;
    background-color: #E4DFE9;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}

.qvHeader .iconButtonLeft {
    background-color: #7C596A;
    max-width: 23px;
    max-height: 23px;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
}

.qvHeader .iconButtonLeft:hover {
    background-color: #4E253A;
}

.qvHeader .iconButtonRight {
    background-color: #7C596A;
    max-width: 23px;
    max-height: 23px;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
}

.qvHeader .iconButtonRight:hover {
    background-color: #4E253A;
}

.qvButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.viewMoreButton {
    min-width: 150px;
    background-color: #7C596A;
    color: white;
    border-style: none;
    border: 1px solid #7C596A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    margin: 0px 40px;
    cursor: pointer;
    height: 45px;
    margin-bottom: 20px;
}

.viewMoreButton:hover {
    background-color: #4E253A;
}

.circularProgressDiv {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
}

.prevButton {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: #7C596A;
    color: #E4DFE9;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.prevButton:hover {
    background-color: #4E253A;
}

.nextButton {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    background-color: #7C596A;
    color: #E4DFE9;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.nextButton:hover {
    background-color: #4E253A;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paper {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    padding: 10px;
    align-items: center;
    width: 80vw;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.menuTitle {
    background-color: #ffff;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    width: 100%;
    align-items: center;
    font-size: 16px;
    font-family: "GTWalsheimProRegular";
}

.titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 22px;
    padding: 10px;
    color: #4E253A;
    text-align: left;
}

.userInfoMessage {
    font-family: "GTWalsheimProRegular";
    /* margin-left: auto; */
    margin-right: auto;
    color: #898B8F;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 30px;
    /* text-align: center; */
}

.userQuestionMessage {
    font-family: "GTWalsheimProRegular";
    /* margin-left: auto; */
    margin-right: auto;
    color: #4E253A;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    margin-bottom: 15px;
    margin-top: 25px;
}

.cancelButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 25vw;
    background-color: #fff;
    color: #8D919A;
    font-weight: 600;
    border: 1px solid #8D919A;
    border-radius: 10px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}

.cancelButton:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
}

.submitButton {
    margin-top: 10px;
    margin-left: 80px;
    margin-right: 10px;
    min-width: 25vw;
    background-color: #7C596A;
    color: white;
    /* font-weight: 200; */
    border: 1px solid #7C596A;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    margin-bottom: 10px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #AA346F;
    border: 1px solid #AA346F;
}

.logoutButtonDiv {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    padding-right: 20px;
}