.avatar {
    border: 1px solid #4E253A;
}

.avatarTitleDiv {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.qvHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-top: 0px;
    justify-content: center;
    background-color: #E4DFE9;
    padding-bottom: 30px;
}

.qvHeader .iconButtonLeft {
    background-color: #7C596A;
    max-width: 23px;
    max-height: 23px;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
}

.qvHeader .iconButtonLeft:hover {
    background-color: #4E253A;
}

.qvHeader .iconButtonRight {
    background-color: #7C596A;
    max-width: 23px;
    max-height: 23px;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
}

.qvHeader .iconButtonRight:hover {
    background-color: #4E253A;
}

.qvButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .viewMoreButton {
    min-width: 150px;
    background-color: #7C596A;
    color: white;
    border-style: none;
    border: 1px solid #7C596A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    margin: 0px 40px;
    cursor:pointer;
    height:45px;
    margin-bottom: 20px;
  }

  .viewMoreButton:hover {
    background-color: #4E253A;
  }

  .qvFooter {
    height: 90px;
    align-items: center;
    justify-content: center;
  }

  .circularProgressDiv {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
  }