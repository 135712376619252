.rangeCard {
    background-color: #DBE1EF;
    border-radius: 20px;
    /* box-shadow: 0px 2px 9px 0px #888888; */
    /* box-shadow: 0px 1px 9px 0px #dee2e6; */
    /* border-bottom: 1px solid rgb(255, 203, 119, 0.4); */
    padding: 10px;
    margin-top: 10PX;
    margin-bottom: 10px;
    /* width: 900px; */
    margin-left: 10px;
    margin-right: 10px;
   
  }

  .rangeContainer{
    background-color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: GTWalsheimProRegular;
    margin-bottom: 10px;
    /* border: 1px solid black; */
    border-radius: 20px;
    padding: 5px;
    box-shadow: 0px 1px 3px 0px #dee2e6;
  }

  .rangeTitleHigh{
      color: #ef233c;
      font-size: 1.1em;
  }

  .rangeTitleMod{
    color: #fecf3e;
    font-size: 1.1em;
  }
.rangeTitleNormal{
    color: #52b788;
    font-size: 1.1em;
}
  .rangeWrapper{
      display: flex;
      margin-left: auto;
      margin-right: auto;
      /* width: 200px; */
      align-items: center;
      text-align: center;
     
  }

  .toIndicator{
    margin-left: auto;
    margin-right: auto; 
  }

  /* High Range */

  .highRangeMIN{
color: #f25c54;
margin-left: auto;
margin-right: auto;
font-size: 1.2em;
  }

  .highRangeMAX{
color: #ef233c;
margin-left: auto;
margin-right: auto;
font-size: 1.2em;
  }

/* High Range */


/* Mod Range */

.modRangeMIN{
    color: #fecf3e;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2em;
      }
    
      .modRangeMAX{
    color: #fecf3e;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2em;
      }
      /* Mod Range */

      /* Normal Range */
      
  .normalRangeMIN{
    color: #52b788;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2em;
      }
    
      .normalRangeMAX{
    color: #52b788;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2em;
      }
      /* Normal Range */