.rulesContainer{
    margin-top: 15px;
}

.card {
    /* background-color: #ffffff; */
    background-color: #DBE1EF;
    border-radius: 20px;
    /* box-shadow: 0px 2px 9px 0px #888888; */
    box-shadow: 0px 1px 9px 0px #dee2e6;
    border-bottom: 1px solid rgb(255, 203, 119, 0.4);
    padding: 10px;
    margin-top: 10px;
    /* width: 900px; */
    margin-left: 10px;
    margin-right: 10px;
  }

  .titleDiv{
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      font-family: GTWalsheimProRegular;
      font-size: 20px;
      color: #001d3d;
  }

.actionContainer{
    /* padding: 5px 0px 0px 5px; */
    margin-right: 20px;
    margin-left: auto;
    margin-top: 10px;  
}

.editActionDiv{
    margin-left: auto;
    display: flex; 
    align-items: center;
    text-align: center;
    width: 50px;
    color: #fff;
    background-color: #725F7F;
    border-radius: 20px;
    border: 1px solid #725F7F;
    padding: 10px 15px 10px 15px;
}

.editActionDiv:hover {
    color: #725F7F;
    background-color: #fff;
    border: 1px solid #725F7F;
    border-radius: 20px;
    /* padding: 10px 10px 10px 10px; */
    cursor: pointer;
}

.actionName{
    margin-left: 5px;
}

