.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: capitalize;
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  /* color: blueviolet; */
  color: #793f98;
  margin-top: 10px;
  margin-bottom: 10px;
}

.inputStyle {
  min-width: 520px;
  min-height: 30px;
  background-color: #ffffff;
  /* color: blueviolet; */
  color: #3e3f40;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  caret-color: #793f98;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
}

.inputStyle:focus {
  border-radius: 17px;
  background-color: #ffffff;
  border-color: #793f98;
}

.inputStyle::placeholder {
  color: #793f98;
  font-size: 1.2em;
  font-style: italic;
}
