.faqTitle {
    color:#7C596A;
    font-family: GTWalsheimProRegular;
    font-size: 25px;
    font-weight:1000;
    margin: 8px 16px;
  }

  .question {
    color: black;
    font-family: GTWalsheimProRegular;
    font-size: 15px;
    font-weight:bold;
  }

  .answer {
    color: black;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
  }