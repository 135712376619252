.modal {
  align-items: center;
  justify-content: center;
  position: relative;
}



.warningPaper {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  align-items: center;
  width: 400px;
  height: 350px;
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}



.menuTitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #FFF1F8;
  font-size: 26px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 8px;
  color: #4E253A;
}

.titleText {
  font-family: GTWalsheimProRegular;
  font-weight: bold;
  /* margin-left: auto; */
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 25px;
  padding: 10px;
  /* text-align: center; */
}

.subTitle {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
  font-size: 18px;
  color: #F14C00;
}

.msgPriority {
  font-family: GTWalsheimProRegular;
  /* margin-left: auto; */
  margin-right: auto;
  color: #FE6B32;
  font-size: 18px;
  margin-left: 10px;
  /* text-align: center; */
}


.warningModalContent {
  /* background-color: red; */
  margin-top: 20px;
}

.warningMsgContent {
  text-align: center;
  margin-bottom: 10px;
}

.contentText {
  font-style: GTWalsheimProRegular light;
  /* margin-left: auto; */
  margin-right: auto;
  color: #4E253A;
  font-size: 18px;
  margin-left: 10px;
  margin-top: 10px;
  /* text-align: center; */
}

.buttonDiv {
  text-align: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 20px; */
}

.logoutButton {
  border-radius: 50px;
  border: 2px solid #F14C00;
  color: #F14C00;
  background-color: #fff;
  min-width: 150px;
  padding: 5px;
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.logoutButton:hover {
  background-color: #FFE0F0;
  color: #AA346F;
  border: none;
}

.continueButton {
  border-radius: 50px;
  border: 2px solid #F14C00;
  color: #fff;
  background-color: #F14C00;
  min-width: 150px;
  padding: 5px;
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.continueButton:hover {
  background-color: #AA346F;
  border: 2px solid #AA346F;
}

.timerDiv {
  display: flex;
  text-align: center;

}

.counterTime {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.timerNumber {
  border-radius: 15px;
  border: 1px solid #D3D8E1;
  color: #FE6B32;
  background-color: #fff;
  min-width: 40px;
  padding: 10px;
  font-family: GTWalsheimProRegular;
  font-size: 30px;
  margin-left: 5px;
  margin-right: 5px;
}

.timerSeparator {
  border-radius: 15px;
  /* border: 1px solid #D3D8E1; */
  color: #FE6B32;
  background-color: #fff;
  /* min-width: 50px; */
  padding: 10px;
  font-family: GTWalsheimProRegular;
  font-size: 30px;
  margin-left: 3px;
  margin-right: 3px;
}