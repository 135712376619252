.wrapperDiv {
    align-items: center;
}
.card {
  background-color: #F9F2F2; /* rgb(227,233,250,0.5); */
  border-radius: 20px;
  box-shadow: 0px 2px 7px 0px #cfcece;
  border-bottom: 1px solid rgb(255, 203, 119, 0.4);
  padding: 20px;
  margin-top: 25px;
/*   margin-left: auto;
  margin-right: auto; */
}

.inputWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.buttonsWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.buttonDiv {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}
.submitButton {
  min-width: 150px;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border: 0px solid #7C596A;
  border-radius: 15px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin: 20px 40px 40px 40px;
  cursor:pointer;
}

.nextButton {
    min-width: 150px;
    background-color: #F1D4D4;
    color: #4E253A;
    /* font-weight: 200; */
    border-style: none;
    /* border-width: 12px;*/
    border-radius: 15px;
    border: 0px solid #8D919A;
    padding: 10px 10px 10px 10px;
    /*box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);*/
    outline: none;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    margin: 20px 40px 40px 0px;
    cursor: pointer;
    font-weight: 700;
  }

.submitButton:hover {
    background-color: #4E253A;
}

.nextButton:hover {
    color: #FFFFFF;
    background-color: #F14C00;
    border: 0px solid #8D919A;
}

.phoneStyle {
  min-width: 213px;
  background-color: #ffffff;
  /* color: blueviolet; */
  color: #4E253A;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 10px 10px 10px 10px !important;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  /* caret-color: #7400b8; */
  caret-color: #793f98;
  font-family: GTWalsheimProRegular !important;
  font-size: 15px !important;
  border: 1px solid #4E253A;
  height:45px;
}

.phoneCountries {
    margin-left: 5px !important;
    margin-right: 5px !important;
}
