.topDiv {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  padding: 10px;
}

.tabsContainer {
  padding: 10px;
}

.avatarDiv {
  margin-left: 10px;
}

.patientName {
  font-family: GTWalsheimProRegular;
  font-size: 20px;
  color: #29304c;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 10px 10px 10px 10px;
  background-color: #fbfefb;
}

.topBarButtons {
  margin-right: 20px;
  display: flex;
  align-items: center;
  /* float: right;
    top: 0;
    right: 0; */
  margin-left: auto;
  margin-top: 10px;
}

.conditionInfo {
  display: flex;
  align-items: center;
}

.riskLevel {
  color: #ef476f;
  margin-left: 30px;
  font-family: GTWalsheimProRegular;
}

.diagnosis {
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  margin-left: 30px;
}

.enrollmentStatus {
  display: flex;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  color: #193b6e;
  border: 1px solid #e9ecef;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  align-items: center;
}

.enrollmentStatusEnrolled {
  display: flex;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  color: #4E253A;
  border: 1px solid #e9ecef;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  align-items: center;
}

.enrollmentStatusIcon {
  margin-top: 3px;
  margin-bottom: auto;
  margin-right: 7px;
}

.avatarStyle {
  border: 2px solid #ef476f;
}

.topIconContainer {
  /* margin-left: 10px; */
  background-color: transparent;
}

.iconBarStyles {
  margin-right: 30px;
}

.iconButtonStyle {
  color: #535354;
}

.sideIconsDiv {
  background-color: brown;
}

.containerPatientDetails {
  display: flex;
}

.deviceId {
  display: flex;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  /* margin-left: 10px;
    margin-right: 10px; */
  color: #4E253A;
  border: 1px solid #e9ecef;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  align-items: center;
}

.adherence {
  display: flex;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  color: #4E253A;
  border: 1px solid #e9ecef;
  border-radius: 50px;
  padding: 10px 15px 10px 15px;
  align-items: center;
}

.subjectId {
  display: flex;
  flex-direction: row;
  font-family: GTWalsheimProRegular;
  font-size: 18px;
  color: #FFFFFF;
  border: 0px;
  border-radius: 50px;
  background-color: #4E253A;
  padding: 0px 0px 0px 0px;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.avatar {
  border: 0px solid #F1D4D4;
  margin-right: 15px;
}

.patientIdDiv {
  display: flex;
  flex-direction: row;
  font-family: GTWalsheimProRegular;
  font-size: 18px;
  color: #FFFFFF;
  border: 0px;
  border-radius: 50px;
  background-color: #4E253A;
  padding: 0px 0px 0px 0px;
  align-items: center;
}

.avatar {
  border: 0px solid #4E253A;
  margin-right: 15px;
}

/* Styles for the timer  */

.timerDiv {
  display: flex;
  margin-left: 20px;
}

.timer {
  color: #535354;
  font-size: 20px;
  font-family: GTWalsheimProRegular;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
}

.buttonDiv {
  display: flex;
  margin-left: 5px;
  text-align: center;
  align-items: center;
  /* border: 1px solid #535354;
      border-radius: 20px; */
}

.subjectIdButtons {
  border: 1px solid #E3C2C2;
  border-radius: 50px;
  background-color: #E3C2C2;
  height: 40px;
  width: 80px;
  margin-left: 15px;
  text-align: center;
  justify-content: center;
}

.subjectIdButtons .iconButtonLeft {
  background-color: #7C596A;
  max-width: 23px;
  max-height: 23px;
  align-items: center;
  justify-content: center;
}

.subjectIdButtons .iconButtonLeft:hover {
  background-color: #4E253A;
}

.subjectIdButtons .iconButtonWrapper {
  max-width: 40px;
  max-height: 40px;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
}

.subjectIdButtons .iconButtonRight {
  background-color: #7C596A;
  max-width: 23px;
  max-height: 23px;
  align-items: center;
  justify-content: center;
}

.subjectIdButtons .iconButtonRight:hover {
  background-color: #4E253A;
}

.MuiPaper-root {
  padding: 0px;
}

.profileField {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: baseline;
}

.notesDrawerButton {
  position: fixed;
  bottom: 10px;
  right: 5px;
  background-color: #7C596A;
  border-radius: 50px;
}

.notesDrawerButton .iconNotesDrawerButton {
  background-color: #F1D4D4;
  max-width: 30px;
  max-height: 30px;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.notesDrawerButtonInside {
  position: absolute;
  bottom: 10px;
  right: 5px;
  background-color: #7C596A;
  border-radius: 50px;
}

.notesDrawerButtonInside .iconNotesDrawerButton {
  background-color: #F1D4D4;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.notesButton {
  position: absolute;
  top: 30%;
  padding-left: 10px;
}

.notesButton .iconNotesButton {
  background-color: #F5EAEA;
  width: 25px;
  height: 25px;
}

.timerButton {
  position: absolute;
  top: 35%;
  padding-left: 10px;
}

.timerButton .iconNotesButton {
  background-color: #F5EAEA;
  width: 25px;
  height: 25px;
}

.noteDrawerCloseIcon {
  background-color: #F5EAEA;
  width: 25px;
  height: 25px;
  margin-left: 0px;
}

.drawerHeader {
  display: flex;
  flex-direction: row;
  column-gap: 90px;
  justify-content: space-between;
}

.headerLabel {
  font-family: GTWalsheimProRegular;
  font-size: large;
  font-weight: bold;
  color: #4E253A;
  min-width: 157px;
  float: left;
}

.allNotesButton {
  display: flex;
  background-color: #7C596A;
  border-radius: 50px;
  width: 114px !important;
  color: #fbfefb;
  font-size: smaller;
  font-family: GTWalsheimProRegular;
  align-items: center;
  justify-content: left;
  margin-top: 10px;
  margin-left: 5px;
}

.allNotesButtonIcon {
  background-color: #E3C2C2;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.searchBar {
  position: relative;
  width: 95%;
  height: 30px;
  padding-right: 10px;
  border-radius: 5px;
  font-size: 14px !important;
  box-shadow: 0px 0px 4px #ccc;
  align-items: center;
  justify-content: left;
  margin-top: 20px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #FCF9FA;
}

.searchIcon {
  height: 20px !important;
  width: 20px !important;
  float: left;
  margin-right: 5px;
  color: #4E253A;
}

.addNotesDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  padding-top: 20px;
  padding-left: 5px;
  align-items: center;
  justify-content: left;
  font-size: medium;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
}

.addNotesIcon {
  background-color: #E3C2C2;
  width: 20px;
  height: 20px;
}

.tagsMenu {
  padding-top: 10px;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  column-gap: 0px !important;
  align-items: center;
  justify-content: left;
}

.notesEditor {
  position: relative !important;
  width: 95%;
  height: 150px;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 14px !important;
  align-items: start !important;
  justify-content: left !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  display: inline-block !important;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
  background-color: #FCF9FA;
}

.notesReader {
  position: relative !important;
  width: 95%;
  height: 25px;
  padding-right: 20px !important;
  border-radius: 10px;
  font-size: 14px !important;
  align-items: start !important;
  justify-content: left !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  display: inline-block !important;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
  background-color: #FCF9FA;
}

.notesReaderExpanded {
  position: relative !important;
  width: 95%;
  height: 100%;
  padding-right: 10px;
  border-radius: 10px;
  font-size: 14px !important;
  align-items: start !important;
  justify-content: left !important;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  display: inline-block !important;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
  background-color: #FCF9FA;
}

.addNotesButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  column-gap: 20px;
}

.submitButton {
  border-radius: 50px;
  padding-right: 6px;
  padding-left: 6px;
  color: #ffffff;
  background-color: #7C596A;
  font-family: GTWalsheimProRegular;
  font-size: 13px;
  border: 0px;
}

.submitButton:hover {
  background-color: #4E253A;
  cursor: 'pointer' !important;
}

.recentNotesDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: left;
  font-size: medium;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
}

.bookmarkedNotesDiv {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  padding-top: 15px;
  padding-left: 5px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: left;
  font-size: medium;
  font-family: GTWalsheimProRegular;
  font-weight: bold;
}

.notesByDiv {
  font-size: 11px;
  font-family: GTWalsheimProRegular !important;
  margin-left: 5px;
  margin-bottom: 5px !important;
  background-color: #cccccc;
  width: fit-content;
  border-radius: 50px;
  padding-left: 5px;
  height: 15px;
  margin-top: 10px;
  padding-right: 5px;
}

.dummyNotesByDiv {
  font-size: 11px;
  font-family: GTWalsheimProRegular !important;
  margin-left: 5px;
  margin-bottom: 5px !important;
  width: 125px;
  border-radius: 50px;
  padding-left: 5px;
  height: 15px;
  margin-top: 10px;
}

.notesCompressedFooter {
  display: flex;
  justify-content: space-between;
}

.notesCompressedFooterReverse {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-between;
}

.compressNotesButton {
  float: right !important;
  margin-right: 5px !important;
}

.expandNotesButton {
  float: right;
  margin-right: 0px;
  margin-top: -34px;
}

.inputHeader {
  display: flex;
  flex-direction: row;
  font-size: 14px !important;
  border-radius: 5px;
  align-items: center;
  position: relative !important;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 10px;
  padding-left: 5px;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
  font-weight: bold;
}

.inputFooter {
  display: flex;
  flex-direction: row;
  column-gap: 205px;
  font-size: 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: left;
  position: relative !important;
  border-radius: 5px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding-left: 5px;
  overflow: hidden;
  font-family: GTWalsheimProRegular !important;
}

.pinButton {
  height: 15px !important;
  width: 15px !important;
  color: #4E253A;
  font-size: 10px;
}

.bookmarkButton {
  float: left !important;
}

.notesTextArea {
  /*border: 1px solid #cccccc;*/
  box-shadow: 0px 0px 4px #ccc;
  border-radius: 6px;
  margin-left: 5px;
  margin-right: 7px;
  background-color: #FCF9FA;
  padding-bottom: 1px;
  padding-left: 2px;
}

.selectedTags {
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
  justify-content: left;
}

.selectedTagName {
  font-size: 11px;
  background-color: #7C596A;
  color: #ffffff;
  border-radius: 50px;
  border: 1px solid #7C596A;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.tagNameAdherence {
  font-size: 11px;
  border-radius: 50px;
  border: 1.5px solid #A6A1D0;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #172228;
  font-family: GTWalsheimProRegular !important;
}

.selectedTagAdherence {
  font-size: 11px;
  background-color: #A6A1D0;
  color: #172228;
  border-radius: 50px;
  border: 1px solid #A6A1D0;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.tagNameMedication {
  font-size: 11px;
  border-radius: 50px;
  border: 1.5px solid #9AB4D1;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #172228;
  font-family: GTWalsheimProRegular !important;
}

.selectedTagMedication {
  font-size: 11px;
  background-color: #9AB4D1;
  color: #172228;
  border-radius: 50px;
  border: 1px solid #9AB4D1;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.tagNameVitals {
  font-size: 11px;
  border-radius: 50px;
  border: 1.5px solid #EDC490;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #172228;
  font-family: GTWalsheimProRegular !important;
}

.selectedTagVitals {
  font-size: 11px;
  background-color: #EDC490;
  color: #172228;
  border-radius: 50px;
  border: 1px solid #EDC490;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.tagNameDevice {
  font-size: 11px;
  border-radius: 50px;
  border: 1.5px solid #A2DBD3;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #172228;
  font-family: GTWalsheimProRegular !important;
}

.selectedTagDevice {
  font-size: 11px;
  background-color: #A2DBD3;
  color: #172228;
  border-radius: 50px;
  border: 1px solid #A2DBD3;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.tagNameOthers {
  font-size: 11px;
  border-radius: 50px;
  border: 1.5px solid #E59381;
  padding-left: 2px;
  padding-right: 2px;
  max-height: 14px;
  padding-top: 1px;
  padding-bottom: 1px;
  color: #172228;
  font-family: GTWalsheimProRegular !important;
}

.selectedTagOthers {
  font-size: 11px;
  background-color: #E59381;
  color: #172228;
  border-radius: 50px;
  border: 1px solid #E59381;
  padding-left: 4px;
  padding-right: 4px;
  max-height: 14px;
}

.createdDateTime {
  font-size: 11px !important;
  font-weight: normal !important;
  color: #4E253A;
  float: right !important;
  background-color: #F1D4D4;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 50px;
}

.createdBy {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  align-items: center !important;
  column-gap: 3px;
}

.drawerFooter {
  display: flex;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  font-family: GTWalsheimProRegular !important;
  color: #FFFFFF;
  background-color: #7C596A;
  align-items: center;
  padding-left: 100px;
}

.drawerFooter:hover {
  background-color: #4E253A;
  cursor: pointer;
}

.rootDiv {
  overflow: hidden !important;
}

.rootDiv::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.bookmarkedNotesContainer {
  margin-bottom: 50px;
}

.characterLimitDiv {
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 96%;
  margin-top: 4px;
  font-size: 12px;
  color: rgb(185, 185, 185);
  font-family: "GTWalsheimProRegular";
}

.characterCountRed {
  color: red !important;
}

.modal {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
  /* margin-left: 10%;
          margin-right: 90%;
          top: 50; */
}

.paperUnassignML {
  background-color: #fff;
  outline: none;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  width: 400px;
  /* height: 350px; */
  overflow-x: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menuTitle {
  background-color: #ffff;
  display: flex;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: "GTWalsheimProRegular";
}

.titleText {
  font-style: "GTWalsheimProRegular";
  font-weight: bold;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 28px;
  padding: 10px;
  color: #4E253A;
}

.patientListDiv {
  width: fit-content;
  padding: 10px;
  font-style: "GTWalsheimProRegular";
  font-size: 18px;
}

.menuButtons {
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: space-between;
}

.menuButtons2 {
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: center;
}

.abortButton {
  display: flex;
  padding: 10px;
  width: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #4E253A;
  color: #ffffff;
  font-size: 16px;
  font-style: "GTWalsheimProRegular";
}

.abortButton:hover {
  cursor: pointer;
  background-color: #7C596A;
}

.continueButton {
  display: flex;
  padding: 10px;
  width: 120px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: #4E253A;
  color: #ffffff;
  font-size: 16px;
  font-style: "GTWalsheimProRegular";
}

.continueButton:hover {
  cursor: pointer;
  background-color: #7C596A;
}

.circularProgress {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  align-items: center;
}

.circularProgress svg {
  margin: 0 auto;
  display: block;
  color: #4E253A;
}