.labelDivStyles {
  text-align: left;
  align-items: center;
}

.labelStyles {
  text-transform: none;
  font-size: 16px;
  font-family: GTWalsheimProRegular;
  /* color: blueviolet; */
  color: #793f98;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.inputStyle {
  width: 150px;
  background-color: #ffffff;
  /* color: blueviolet; */
  color: #3e3f40;
  font-weight: 200;
  border-style: none;
  border-width: 10px;
  border-radius: 15px;
  padding: 0px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  /* caret-color: #7400b8; */
  caret-color: #793f98;
  font-family: GTWalsheimProRegular;
  font-size: 15px;
  margin: 0px 10px;
  height:45px;
}

.inputStyle:focus {
  border-radius: 17px;
  background-color: #ffffff;
  border-color: #793f98;
}

.inputStyle::placeholder {
  color: #8c8490;
  font-size: 1.2em;
  font-style: italic;
  font-size: 15px;
}


/* Input media queries */
@media screen and (min-width: 600px) {
  /* For Tablets */
  .inputStyle {
    width: 100px;
  }
}


@media screen and (min-width: 1024px) {
  /* //For Large Laptops */
  .inputStyle {
    width: 100px;
  }
}

@media screen and (min-width: 2550px) {
  /* //For Large Laptops */
  .inputStyle {
    width: 150px;
  }
}


/* Input media queries */