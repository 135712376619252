
.wrapper {
    display: flex; 
    flex-direction: row; 
    justify-content: center; 
    padding: 2em;
  }

.cardWrapper {
    margin: 2%; 
    width: 400px;
}

.locations, .locations p{ 
    font-size: 20px; 
    background-color: #4E253A; 
    border-radius: 15px; 
    margin: 10px 5px 10px 10px;
    width:80%;
}

.locText {
    color: white;
    margin-left: 1em;
    font-family: "GTWalsheimProRegular";
    padding-left: 15px;
}

.project {
    align-items: center;
}

.MuiTypography-h4 {
    font-family: "GTWalsheimProRegular";
    font-size: 40px;
    font-weight: 500;
    line-height: 1.235;
    letter-spacing: 0.00735em;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}

.circularProgress svg{
    margin: 0 auto;
    display: block;
    color:#4E253A;
}

.accordianStyle {
    display: block;
    align-items: center;
}

.containerForMessage {
    height: 85%;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
  }

.msgNoIPL {
    font-family: "GTWalsheimProRegular";
    font-size: 24px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 0.00735em;
}

.phoneNum {
    color: #4E253A;
    font-size: 28px;
    font-weight: 550;
    letter-spacing: 0.0235em;
}