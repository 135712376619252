.accContainer {
    display: flex;
    flex-direction: column;
    height: max(100vh,800px);

}

.selectedMLDiv {
    display: flex;
    flex-direction: row;
    width: 97%;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    justify-content: space-between;
    margin: 20px;
    background-color: #FFF2F9;
    padding: 5px;
    box-shadow: 0px 1px 2.4px 1px rgba(0, 0, 0, 0.10);
}

.hubIconDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    border-radius: 50px;
    padding: 4px;
}

.MLIdDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    padding-left: 6px;
    padding-right: 10px;
    font-size: 20px;
    font-weight: 700;
    font-family: "GTWalsheimProRegular";
    color: #ffffff;
    background-color: #4E253A;
    border: 1px solid #fff;
}

.unpairButton {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 6px;
    font-size: 16px;
    font-weight: 700;
    font-family: "GTWalsheimProRegular";
    background-color: #F14C00;
    color: #ffffff;
    border: 1px solid #fff;
}

.unpairButton:hover {
    cursor: pointer;
    background-color: #AA346F;
}

.footerDiv {
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    position: fixed;
    bottom: 0;
}

.footerButtonsDiv {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    width: 100%;
    padding-top: 10px;
    background-color: #ffffff;
}

.warningDiv {
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    color: #4E253A;
    background-color: #FFF2F9;
    width: fit-content;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    font-size: 20px;
    border-top: 1px solid #E0B3C9;
    border-left: 1px solid #E0B3C9;
    border-right: 1px solid #E0B3C9;
}

.footerButtons {
    align-content: center;
    justify-content: center;
    justify-items: center;
    display: flex;
    flex-direction: row;
    column-gap: 60px;
}

.warningIcon {
    display: flex;
    background-color: #F14C00;
    color: #FFFFFF;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    padding-top: 5px;
    padding-right: 6px;
    padding-bottom: 7px;
    padding-left: 6px;
    margin-right: 10px;
}

.questionIcon {
    display: flex;
    background-color: #FFFFFF;
    color: #4E253A;
    border-radius: 50px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    padding-left: 0px;
    margin-left: 20px;
    border: 1px solid #4E253A;
}

.pairButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    justify-items: center;
    align-content: center;
    background-color: #F14C00;
    color: #ffffff;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    padding-left: 4px;
    padding-right: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    column-gap: 5px;
    width: 140px;
}

.pairButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.enterManuallyButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    justify-items: left;
    align-content: center;
    background-color: #65394F;
    color: #F7E7E7;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    padding-left: 4px;
    padding-right: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 50px;
    column-gap: 10px;
    width: 140px;
    text-align: center;
}

.enterManuallyButton:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.manualLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}

.paperDiv {
    margin: 20px;
    flex-grow: 1;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 80px;
}

.patientInfoDiv {
    border-radius: 5px;
    background-color: #FFF6FC !important;
    margin-left: 0px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.patientInfoDiv {
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.patientInfoDiv::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.patientInfoDiv2 {
    border-radius: 5px;
    padding: 0px;
    background-color: #fdf8fb !important;
    margin-left: 2px;
}

.profileSummary {
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    background-color: #FFEFE8;
    border-radius: 20px 20px 0px 0px;
    padding: 20px;
    padding-bottom: 5px;
}

.pfpDiv {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
}

.nameAndIdDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.patientName {
    width: 100%;
    color: #F14C00;
    font-family: "GTWalsheimProRegular";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.076px;
}

.patientId {
    margin-top: -20px;
    font-size: 22px;
    font-weight: 500;
}

.otherDetails {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
}

.otherDetails1 {
    display: grid;
    grid-template-columns:3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
}

.otherDetails2 {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
}

.infoDiv {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.infoDiv1 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.infoLabel {
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    font-weight: bold;
    color: rgb(153, 148, 148);
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    color: #F14C00;
}

.infoContent {
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    color: #4E253A;
    font-weight: bold;
}

.patientInfo {
    display: flex;
    flex-direction: column;
}

.patientInfoTitle {
    display: flex;
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
    color: #4E253A;
    font-weight: bold;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.contHeader {
    padding: 4px 60px;
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px 1.529px 1.529px 0px rgba(0, 0, 0, 0.25);
}

.patientInfoItem {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    margin-left: 20px;
    color: #000;
    border-radius: 10px;
    background-color: #ffffff;
    padding-top: 4px;
    padding-bottom: 4px;
    width: fit-content;
    padding-left: 8px;
    padding-right: 8px;
}

.patientInfoLabel {
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
}


.patientInfoLabel1 {
    font-family: "GTWalsheimProRegular";
    font-size: 20px;
    font-weight: bold;
    overflow: hidden;
    padding-top: 80px;
}

.patientInfoContent {
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    font-weight: normal;
}

.modal {
    /* display: flex; */
    align-items: center;
    justify-content: center;
    position: relative;
    /* margin-left: 10%;
            margin-right: 90%;
            top: 50; */
}

.paperUnassignML {
    background-color: #fff;
    outline: none;
    border-radius: 20px;
    align-items: center;
    width: 400px;
    /* height: 350px; */
    overflow-x: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: 340px;
    width: 350px;
}

.menuTitle {
    background-color: #FFF1F8;
    display: flex;
    /* border-bottom: 1px solid gray; */
    text-align: center;
    align-items: center;
    font-size: 20px;
    font-family: "GTWalsheimProRegular";
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    color: #4E253A;
}

.titleText {
    font-style: "GTWalsheimProRegular";
    font-weight: bold;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
    padding: 10px;
    color: #4E253A;
}

.patientListDiv {
    width: fit-content;
    padding: 10px;
    font-style: "GTWalsheimProRegular";
    font-size: 18px;
}

.circularProgress {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
}

.circularProgressPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12vh;
}

.circularProgress svg {
    margin: 0 auto;
    display: block;
    color: #4E253A;
}

.uaSuccessMsg {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    height: 10vh;
    color: #F14C00;
}

.touchIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50px;
    background-color: #F7E7E7;
}

.sensorIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 50px;
    background-color: #FFFFFF;
}

.patientInfo1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 40px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.column1 {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.column2 {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.column3 {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.column4 {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.eachElement {
    display: flex;
    flex-direction: column;
}

.eachElement1 {
    display: flex;
    flex-direction: row;
    column-gap: 2vw;
}

.eachSubElement {
    display: flex;
    flex-direction: column;
}

.dataDiv {
    background-color: #FDEAFF;
    display: flex;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    height: 3vh;
    padding-left: 10px;
    width: 15vw;
    border: 0.781px solid #A040AB;
    align-items: center;
    justify-content: left;
}

.dataDivEdit {
    background-color: white;
    display: flex;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    height: 3vh !important;
    padding-left: 10px;
    width: 15vw;
    border: 0.781px solid #A040AB;
    align-items: center;
    justify-content: left;
}

.dataDiv1 {
    background-color: #FDEAFF;
    display: flex;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    height: 3vh;
    padding-left: 10px;
    width: 2.5vw;
    border: 0.781px solid #A040AB;
    align-items: center;
    justify-content: left;
}

.dataDiv2 {
    background-color: #FDEAFF;
    display: flex;
    font-family: "GTWalsheimProRegular";
    font-size: 15px;
    height: 3vh;
    padding-left: 10px;
    width: 9.5vw;
    border: 0.781px solid #A040AB;
    align-items: center;
    justify-content: left;
}

.labeldiv {
    font-family: "GTWalsheimProRegular";
    font-size: 14px;
}

.editButtonDiv {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;
}

.saveButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 5px 0px 15px 0px;
}

.editButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 20px;
    margin-top: 10px;
    border: 2px solid #7c596a;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #7c596a;
}

.editButton:hover {
    background-color: #4E253A;
    color: #FFFFFF;
    cursor: pointer;
}

.editButtonClicked {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 50px;
    margin-right: 20px;
    margin-top: 10px;
    border: 2px solid #FFFFFF;
    font-size: 14px;
    background-color: #FFE4F2;
    color: #DA96B7;
}

.skipButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 15px;
    border-radius: 50px;
    margin-right: 20px;
    margin-top: 10px;
    border: 2px solid #F14C00;
    font-size: 14px;
    background-color: #FFFFFF;
    color: #F14C00;
    width: 60px;
    cursor: pointer;
    /* margin-right: 35px; */
}

.skipButton:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
}


.saveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 15px;
    border-radius: 50px;
    margin-right: 20px;
    margin-top: 10px;
    border: 2px solid #F14C00;
    font-size: 14px;
    background-color: #F14C00;
    color: #FFFFFF;
    width: 60px;
    cursor: pointer;
}

.saveButton:hover {
    background-color: #AA346F;
    border: 1px solid #AA346F;
}

.saveButtonDisabled {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 15px;
    border-radius: 50px;
    margin-right: 20px;
    margin-top: 10px;
    border: 2px solid #fae0d3;
    font-size: 14px;
    background-color: #fae0d3;
    color: #FFFFFF;
    width: 60px;
    cursor: pointer;
}

.saveButtonDisabled:hover {
    cursor: not-allowed;
}

.mlImg {
    width: 90px;
    height: 120px;
}

.warningImg {
    width: 120px;
    height: 130px;
}

.mouthLabImgDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.submitButton {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 40%;
    border-radius: 30px;
    padding: 10px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
    background-color: #AA346F;
    border: 1px solid #AA346F;
    color: white;
}

.submitButton:hover {
    background-color: #F14C00;
    color: white;
    border: 1px solid #F14C00;
}

.cancelButton {
    margin-top: 10px;
    margin-right: 10px;
    min-width: 40%;
    font-weight: 600;
    border-radius: 30px;
    padding: 10px;
    outline: none;
    font-family: "GTWalsheimProRegular";
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 12px;
    color: #AA346F;
    background-color: #FFFFFF;
    border: 1px solid #AA346F;
}

.cancelButton:hover {
    color: #AA346F;
    background-color: #FFE0F0;
    border: 1px solid #FFE0F0;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 10px;
}

.userQuestionMessage {
    font-family: "GTWalsheimProRegular";
    color: #4E253A;
    font-weight: 600;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
    text-wrap: wrap;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.userQuestionMessage1 {
    font-family: "GTWalsheimProRegular";
    color: #4E253A;
    font-weight: 600;
    font-size: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
    text-wrap: wrap;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.dropdown {
    background-color: #FFFFFF !important;
    font-family: "GTWalsheimProRegular" !important;
    font-size: 15px !important;
    height: 2.5vh !important;
    padding-left: 10px !important;
    width: 16vw !important;
    border: 0.781px solid #A040AB !important;

}

.dropdown2 {
    height: 2.8vh !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FDEAFF !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
}

.dropdown2E {
    height: 3vh !important;
    width: 15.7vw !important;
    border: solid #A040AB 0.5px !important;
    background-color: #FFFFFF !important;
    padding: 0px 5px !important;
    opacity: 1 !important;
}