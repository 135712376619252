.wrapperDiv {
  padding: 10px;
  margin-right: 50px;
  margin-left: 50px;
  align-items: center;
  /* text-align: center; */
}
.card {
  background-color: rgb(227,233,250,0.5);
  border-radius: 20px;
  box-shadow: 0px 2px 9px 0px #888888;
  /* border-bottom: 1px solid rgb(255, 203, 119, 0.4); */
  padding: 20px;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.itemCellContainer{
    background-color: transparent;
    margin-left: 10px;
    margin-right: 10px;
}

.itemCellHeader{
    min-width: 180px;
    max-width: 180px;
    text-align: center;
    align-items: center;
    border: none;
    padding: 10px;
    align-items: center;
    color: #4E253A;
    font-family: "GTWalsheimProRegular";
    font-size: 16px;
    font-weight: bold;
}

.selectDropdown {
    min-width: 200px;
    max-width: 200px;
    font-size: 16px;
    font-family: "GTWalsheimProRegular";
    line-height: 1.42857143;
    color: #4E253A;
    background-color: #fff;
    background-image: none;
    border: 1px solid #8D919A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    padding: 0px 15px;
    height:45px;
  }

.textStyle {
    min-width: 180px;
    max-width: 180px;
    font-size: 16px;
    font-family: "GTWalsheimProRegular";
    line-height: 1.42857143;
    color: #4E253A;
    background-color: #fff;
    background-image: none;
    border: 1px solid #8D919A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    padding: 8px 15px 0px 15px;
    height:35px;
}

.usageStyle {
    min-width: 180px;
    max-width: 180px;
    font-size: 16px;
    font-family: "GTWalsheimProRegular";
    line-height: 1.42857143;
    color: #4E253A;
    background-color: #fff;
    background-image: none;
    border: 1px solid #8D919A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    padding: 0px 15px;
    height: 43px;
}

.deviceFormDiv {
  border-radius: 20px;
  padding: 10px;
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: center;
}
.buttonsWrapper {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
}

.assignButtonContainer{
    text-align: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #4E253A;
    display: flex;
    margin-top: 40px;
    margin-left: 20px;
}
.assignButton {
    min-width: 180px;
    max-width: 180px;
    background-color: #4E253A;
    color: white;
    border: 1px solid #4E253A;
    border-radius: 15px;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
    outline: none;
    padding: 0px 15px;
    font-family: GTWalsheimProRegular;
    font-size: 16px;
    cursor:pointer;
    height: 45px;
  }

.inElementButton {
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.inElementButtonWrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonsContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.buttonDiv {
  align-items: center;
  height: 100%;
  text-align: center;
  display: flex;
  margin: auto;
}
.submitButton {
  min-width: 150px;
  background-color: #7C596A;
  color: white;
  /* font-weight: 200; */
  border-style: none;
  border: 1px solid #7C596A;
  border-radius: 15px;
  padding: 15px 15px 15px 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
}

.submitButton:hover {
  background-color: #4E253A;
}

.baseButtonStyles {
  min-width: 150px;
  color: white;
  border-style: none;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: GTWalsheimProRegular;
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
  border: 1px solid transparent;
}

.deleteButton {
  background-color: #F14C00;
}

.addMoreButton {
  background-color: #0c89dd;
}

.addMoreButton:hover {
  color: #0c89dd;
  border: 1px solid #0c89dd;
  background-color: #ffff;
}

/* Cancel Button  */
.cancelButton {
  min-width: 150px;
  background-color: #FFFFFF;
  color:#8D919A;
  /* font-weight: 200; */
  border-style: none;
  /* border-width: 10px; */
  border-radius: 15px;
  /* padding: 15px 15px 15px 15px; */
  padding: 10px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.14);
  outline: none;
  font-family: "GTWalsheimProRegular";
  font-size: 16px;
  margin-left: 40px;
  margin-right: 40px;
  border: 1px solid #8D919A;
}

.cancelButton:hover {
  color:#4E253A;
  background-color: #F1D4D4;
}


.operationResMsg{
  min-width: 400px;
  max-width: 500px;
  background-color: #8BEEC0;
  border-radius: 10px;
  color: #000;
  font-family: "GTWalsheimProRegular";
  font-size: 18px;
 
  padding: 10px;
  margin-top: 10px;
  /* width: 900px; */
  margin-left: auto;
  margin-right: auto;
}

.success{
  background-color: #8BEEC0;
  color: #000;
  text-align: center;
}

.failed{
  background-color: #ef233c;
  color: #ffff;
  text-align: center;
}